import {Directive, ElementRef, Output, EventEmitter, HostListener, Input} from '@angular/core';
import {ApiService} from '../shared/SDK/api.service';
import {PopupService} from '../services/popup.service';

@Directive({
  selector: '[clickOutside], [btnOpen]'
})

export class ClickOutsideDirective {
  @Input() aType: string; // переменная, которая отслеживает было ли нажатие на стрелочку открывающий дропдаун.
  // Без этой переменной, если дропдаун был закрыт по клику на пустой области, то первый клик по той же стрелочке не открое дропдаун, только со второго клика
  @Output() public clickOutside = new EventEmitter();

  constructor(
    private elementRef: ElementRef,
    public apiService: ApiService,
    private popupService: PopupService,
  ) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement) {
    // console.log(this.popupService.dropOpenedId +"===+++"+ this.elementRef.nativeElement.id);
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    if (this.aType === 'icon' && clickedInside) this.popupService.currentDropOpenedId = '';

    if (this.popupService.dropOpenedId && this.popupService.dropOpenedId === this.elementRef.nativeElement.id) {
      if (!clickedInside) {
        this.clickOutside.emit(null);
      }
    }
  }
}
