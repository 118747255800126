import {Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup } from '@angular/forms';
import {NgOption} from '@ng-select/ng-select';
import {Brand} from '../../types/brand';
import { FilterService } from '../../../services/filter.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

const valueAccessor = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => SearchBrandsComponent),
  multi: true
};

const valueValidator = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => SearchBrandsComponent),
  multi: true
};

// export function brandValidator() {
//   return () => {
//     const component = new SearchBrandsComponent();
//     const error = component.checkSelection();
//     const err = {
//       textError: {
//         message: 'need fill'
//       }
//     };
//     return (error) ? err : null;
//   };
// }

@Component({
  selector: 'app-search-brands',
  templateUrl: './search-brands.component.html',
  styleUrls: ['./search-brands.component.scss'],
  providers: [valueAccessor, valueValidator]
})

export class SearchBrandsComponent implements OnChanges, OnInit, ControlValueAccessor {

  public items: Brand[] = [];
  public selectedBrand: Brand | null;
  public selectedBrandRef;
  public descriptionFirst: string;
  // The internal data model
  private innerValue: any = '';
  // Placeholders for the callbacks which are later provides
  // by the Control Value Accessor
  private onTouchedCallback: () => {};
  private onChangeCallback: (_: any) => {};

  @ViewChild('brandSelect', {static: false}) brandSelect;
  @Input() listBrands: Brand[];
  @Input() errorBrand: boolean;
  @Input() loadingStatus = true;
  @Input() currentBrand: Brand;

  @Output() onSelectBrand = new EventEmitter<Brand>();
  @Input() group: FormGroup;

  validateFn: any = () => {};

  constructor(
    private route?: ActivatedRoute,
    private filterService?: FilterService,
    private translateService?: TranslateService,
  ) {}

  ngOnInit() {
    this.subscribeToLangChanged();
  }

  ngOnChanges(changes: SimpleChanges) { // set current value for town
    if (changes.listBrands && changes.listBrands.currentValue && changes.listBrands.currentValue.length > 0) {
      this.items = [];
      this.items[0] = {description: this.translateService.instant('catalog.all_cars'), slug: 'empty', logo: '', main_image: '', id_1C: '', visibility: 1};
      for (const item of changes.listBrands.currentValue) {
        this.items.push(item);
      }
      this.listBrands = changes.listBrands.currentValue;
      // this.items = changes.listBrands.currentValue;
      this.errorBrand = false;
      this.loadingStatus = false;
      this.selectedBrandRef = this.route.snapshot.params['brandSlug'] || 'empty';
    } else {
      this.items = [];
      this.items[0] = {description: 'Все марки авто', slug: 'empty', logo: '', main_image: '', id_1C: '', visibility: 1};
      this.selectedBrandRef = 'empty';
    }
 }

  onChange() {
    let bFounded = false;
    for (const i in this.items) {
      if (this.selectedBrandRef === this.items[i].slug) {
        bFounded = true;
        this.selectedBrand = this.items[i];
      }
    }
    if (!bFounded) {
      this.onClear();
    }

    if (this.selectedBrand.slug !== 'empty') {
      this.filterService.setFilters({brand: this.selectedBrand});
    } else {
      this.filterService.setFilters({brand: null});
    }

    setTimeout(() => { // нужна задержка, чтоб ng-select заполнился и затем проводить проверку
      this.checkSelection();
      this.onSelectBrand.emit(this.selectedBrand);
    }, 10);
  }

  onClear() {
    this.selectedBrand = null;
    this.onSelectBrand.emit(this.selectedBrand);
  }

  checkSelection() {
    const objectName = document.querySelector('app-search-brands .ng-value-label');
    return objectName === null || !objectName.innerHTML;
  }


  subscribeToLangChanged() {
    return this.translateService.onLangChange.subscribe(x => this.setTranslations());
  }

  setTranslations() {
    this.descriptionFirst = this.translateService.instant('catalog.all_cars');
    this.items = [];
    this.items[0] = {description: this.descriptionFirst, slug: 'empty', logo: '', main_image: '', id_1C: '', visibility: 1};
    if (this.listBrands) {
      for (const item of this.listBrands) {
        this.items.push(item);
      }
    }
  }
  // region ControlValueAccessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }
  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  validate() {
    return this.validateFn();
  }
  // endregion
}

