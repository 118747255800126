import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Brand} from './shared/types/brand';
import {ScrollService} from './services/scroll.service';
import {TranslateService, TranslationChangeEvent} from "@ngx-translate/core";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'zet-finance2-front';

  constructor(
    private router: Router,
    public scrollServices: ScrollService,
    public translateService: TranslateService,
  ) {}

  ngOnInit() {
    { // translate init
      this.translateService.setDefaultLang(environment.defaultLanguage);

      this.translateService.onLangChange
        .subscribe((event: TranslationChangeEvent) => {
          localStorage.setItem('lang', event.lang);
          // this.router.navigate([this.router.getCurrentNavigation().finalUrl]);
        });

      if (localStorage.getItem('lang')) {
        this.translateService.use(localStorage.getItem('lang'))
          .toPromise()
          .then((res) => {  // all ok
          })
          .catch((err) => { // remove not supported selected lang
            localStorage.removeItem('lang');
          });
      } else {
        this.translateService.use(this.translateService.getBrowserLang());
      }
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
