import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog-item-calculate',
  templateUrl: './catalog-item-calculate.component.html',
  styleUrls: ['./catalog-item-calculate.component.scss']
})
export class CatalogItemCalculateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
