import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ApiService} from '../../../../shared/SDK/api.service';
import {Car} from '../../../../shared/types/car';
import {FilterService} from '../../../../services/filter.service';
import {log} from 'util';
import {Brand} from '../../../../shared/types/brand';
import {Model} from '../../../../shared/types/model';

@Component({
  selector: 'app-catalog-list-models',
  templateUrl: './catalog-list-models.component.html',
  styleUrls: ['./catalog-list-models.component.scss']
})

export class CatalogListModelsComponent implements OnInit, OnChanges {
  cars: Car[];
  @Input() mode: string;
  @Input() models: Model[];
  @Output()
  loadMore = new EventEmitter();

  constructor(
    private sdk: ApiService,
    private filterService: FilterService,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.models && changes.models.firstChange === false) {
      this.sortModel({value: 'asc'});
    }
  }

  sortModel(sort) {
    if (sort.value === 'asc') {
      this.models.sort((a, b) => {
        return a.first_car.cost - b.first_car.cost;
      });
    }

    if (sort.value === 'desc') {
      this.models.sort((a, b) => {
        return b.first_car.cost - a.first_car.cost;
      });
    }
  }
}
