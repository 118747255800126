import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../../services/message.service';

@Component({
  selector: 'app-send-question',
  templateUrl: './send-question.component.html',
  styleUrls: ['./send-question.component.scss']
})

export class SendQuestionComponent implements OnInit {

  public helpForm: FormGroup;
  public submitted = false;

  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.helpForm = this.fb.group({
      surname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });
  }

  sendMessage() {
    this.submitted = true;
    if (this.helpForm.status === 'VALID') {
      this.submitted = false;
      this.messageService.sendMessage(this.helpForm.controls, 'consulting');
      this.helpForm.reset();
    }
  }

  get f() {
    return this.helpForm.controls;
  }

  get surname() {
    return this.helpForm.get('surname');
  }

  get phone() {
    return this.helpForm.get('phone');
  }
}
