import {Command} from './Command';
import SomeCheckFormDataResponse from '../responses/SomeCheckFormDataResponse';

export default class SomeCheckFormDataCommand extends Command {
    name = 'some/checkFormData/json';

    usesLanguage = true;

    public handle($arguments: any): Promise<SomeCheckFormDataResponse> {
        console.log('SomeCheckFormDataCommand->handle');

        $arguments['app'] = 'cabinet';

        this.useFakeData = true; // всегда мокаем
        return this.apiResponse<SomeCheckFormDataResponse>('get', this.name, $arguments, SomeCheckFormDataResponse);
    }

    protected fake(method: string, $arguments: any): Promise<any> {
      if ($arguments['height'] === '1') {
        return Promise.reject({
          success: false,
          error: [
            {
              note: 'Паллета привета.'
            }
          ]
        });
      }

      if ($arguments['height'] === '123456') {
        return Promise.resolve({
          success: true,
          data: [
          ]
        });
      }

      return Promise.reject({
          success: false,
          error: {
              code: 'nG12',
              note: 'Слишком много ошибок',
              fields: {
                  height: 'Высота паллеты не может быть менее 50 см',
                  townSender: 'Мы сюда не возим, это ж, блин, Рио-Де-Жанейро!!',
                  tarif: 'Неправильно выбран тариф',
                  weight: 'Нельзя по украинским дорогам возить груз свыше 202 тонн'
              }
          }
      });
    }
}
