import {Component, Input, OnInit} from '@angular/core';
import {Brand} from '../../../../shared/types/brand';

@Component({
  selector: 'app-header-bottom',
  templateUrl: './header-bottom.component.html',
  styleUrls: ['./header-bottom.component.scss']
})

export class HeaderBottomComponent implements OnInit {

  @Input() brands: Brand[];

  constructor() {}

  ngOnInit() {
  }
}
