import {Component, Input, OnInit} from '@angular/core';
import {Model} from '../../shared/types/model';
import {ScrollService} from '../../services/scroll.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-offers-item',
  templateUrl: './offers-item.component.html',
  styleUrls: ['./offers-item.component.scss']
})

export class OffersItemComponent implements OnInit {

  public ref: string;
  @Input() models: Model[];

  constructor(
    public scrollServices: ScrollService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.ref = this.route.snapshot.params['ref'];
  }
}
