import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '[appOnlyDigits], [dots]'
})
export class OnlyDigitsDirective {

  constructor(private el: ElementRef) { }

  @Input() appOnlyDigits: boolean;
  @Input() dots: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = event as KeyboardEvent;
    if (this.appOnlyDigits) {
      const code = this.getCode(e);

      // console.log(code+"; "+e.ctrlKey+"; "+e.metaKey);
      if ([46, 8, 9, 27, 13, 190, 65, 67].indexOf(code) !== -1 ||
        (code === 65 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+C
        (code === 67 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+V
        (code === 97 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+A
        ((code === 118 || code === 86 || code === 1084 || code === 1052) && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+V, lattin, cyrillic big and small
        (code === 120 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+X
        (code === 88 && (e.ctrlKey || e.metaKey)) || // Allow: left, right
        (code === 68 || code === 66 || code === 69 || code === 72 || code === 84) || // del, backspace, home, end, tab
        (code >= 35 && code <= 39) ||
        (code === 46 && this.dots)) {
        // let it happen, don't do anything
        return;
      }
      // Ensure that it is a number and stop the keypress
      if ((e.shiftKey || (code < 48 || code > 57))) {
        e.preventDefault();
      }
    }
  }

  @HostListener('keyup', ['$event']) onKeyUp(event) {
    const elem = +this.el.nativeElement.value;
    if (this.el.nativeElement.value !== undefined && isNaN(elem)) {
      this.el.nativeElement.value = '';
    }
  }

  getCode(e) {
    let code;
    if (e.key !== undefined) {
      code = e.key.charCodeAt(0);
    } else if (e.keyIdentifier !== undefined) {
      code = e.keyIdentifier;
    } else if (e.keyCode !== undefined) {
      code = e.which || e.charCode || e.keyCode;
    }
    return code;
  }
}
