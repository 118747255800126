import {Directive, EventEmitter, HostListener, Inject, Output} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {WINDOW} from '../services/window.service';


@Directive({
  selector: '[appScroll]'
})

export class ScrollDirective {

  @Output() public scrollChange = new EventEmitter<number>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(WINDOW) private window: Window
  ) {}

  @HostListener('document:scroll', []) // for window scroll events
  onScroll() {
    const offset = this.window.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;
    this.scrollChange.emit(offset);
    // console.log(offset);
  }
}
