import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {ApiService} from '../../../../shared/SDK/api.service';
import {Brand} from '../../../../shared/types/brand';
import {IndividualPanelComponent} from '../../../../shared/components/individual-panel/individual-panel.component';

@Component({
  selector: 'app-catalog-list-index',
  templateUrl: './catalog-list-index.component.html',
  styleUrls: ['./catalog-list-index.component.scss'],
})

export class CatalogListIndexComponent implements OnInit {

  public individualSelection = false;
  public count = 0;

  @ViewChild('individualPanelComponent', {static: false}) individualPanelComponent: IndividualPanelComponent;
  @Input() brands: Brand[] = [];

  constructor(
    public api: ApiService,
    protected router: Router,
    @Inject(DOCUMENT) public document: Document,
  ) {}

  ngOnInit() {
    this.api.listEndpoint.getCount().then((data) => this.count = data.count);
  }

  toCatalog(brand: Brand) {
    this.router.navigateByUrl('/catalog/' + brand.slug);
  }

  showIndividualSelection() {

  }
}
