import {ApiService} from '../api.service';
import {CommandInterface} from '../Interfaces/CommandInterface';
import {ModelMapper} from '../modelMapper';

export abstract class Command implements CommandInterface {
  protected abstract name: string;

  protected description: string;

  protected arguments: string;

  protected sdk: ApiService;

  protected abstract usesLanguage: boolean;

  protected useFakeData = false;
  protected bLogCommands = false;

  public getName(): string {
    return this.name;
  }

  public setName(name: string) {
    this.name = name;

    return this;
  }

  public getDescription() {
    return this.description;
  }

  public setDescription(description: string) {
    this.description = description;

    return this;
  }

  public getArguments() {
    return this.arguments;
  }

  public getCommandBus() {
    return this.sdk.getCommandBus();
  }

  public make(sdk: ApiService, $arguments: any): Promise<any> {
    this.sdk = sdk;
    this.arguments = $arguments;
    if (this.usesLanguage) {
      // TODO: add language
    }
    this.useFakeData = this.sdk.getConfig('useFakeBackend');
    this.bLogCommands = this.sdk.getConfig('logCommands');
    return this.handle($arguments);
  }

  protected apiResponse<T>(method: string, name: string, $arguments: any, itemType: any = null, customConfigs: {} = {}): Promise<T> {
    if (this.bLogCommands) {
      console.debug('[APISERVICE] [' + method + ']' + name, $arguments);
    }
    let response: Promise<T>;

    if (this.useFakeData) {
      console.warn('[' + method + ']' + name + ' FAKED');
      response = this.fake(method, $arguments)
        .then(data => ModelMapper.mapper(data.data, itemType))
        .then(res => {
          console.debug('[APISERVICE] R[' + method + ']' + name, ' AS THEN ', res);
          return res;
        })
        .catch(data => {
          if (data['success'] === undefined) {
            throw this.sdk.noResponse({request: {}, response: {data}}); // throw exception upp, if error not from API
          }
          const error = this.sdk.okResponse({data});
          error.serverResponse = ModelMapper.mapper(data, itemType);
          throw error;
        })
        .catch(res => {
          console.debug('[APISERVICE] R[' + method + ']' + name + ' AS CATCH ', res);
          throw res;
        });

      return response;
    }

    response = this.sdk.apiResponse<T>(method, name, $arguments, itemType, customConfigs)
      .then((data) => {
        if (this.bLogCommands) {
          console.debug('[APISERVICE] apiResponse', data);
        }
        return ModelMapper.mapper(data, itemType);
      })
      .catch(data => {
        if (data['success'] === undefined) {
          throw data; // throw exception upp, if error not from API
        }
        throw ModelMapper.mapper(data, itemType);
      });

    if (this.bLogCommands) {
      response.then(res => {
        console.debug('[APISERVICE] R[' + method + ']' + name + ' AS THEN ', res);
      })
      .catch(res => {
        console.debug('[APISERVICE] R[' + method + ']' + name + ' AS CATCH', res);
        throw res;
      });
    }

    return response;
  }

  /**
   * Обработчик этой команды, тут можно добавить представирельную обработку как перед вызовом так и после.
   *
   */
  public abstract handle($arguments: any): Promise<any>;

  /**
   * В режиме моков вместо запроса АПИ будет вызываться этот метод.
   *
   */
  protected abstract fake(method: string, $arguments: any): Promise<any>;
}
