import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PopupService} from '../../../../services/popup.service';
import { Car } from '../../../../shared/types/car';

@Component({
  selector: 'app-catalog-item-filters',
  templateUrl: './catalog-item-filters.component.html',
  styleUrls: ['./catalog-item-filters.component.scss']
})

export class CatalogItemFiltersComponent implements OnInit {

  public filterForm: FormGroup;
  public leasingTerm = 24;
  public params: {[key: string]: any} = {term: 24, user_type: 'person', amount: null};
  @Input() type: string;
  @Input() car: Car;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    public popupService: PopupService,
  ) {
    this.filterForm = fb.group({
      brand: fb.control(''),
      model: fb.control('0'),
      fuel: fb.control(''),
      amount: fb.control(''),
      user_type: fb.control(''),
      typeOfDrive: fb.control(''),
      typeOfBody: fb.control(''),
      transmission: fb.control(''),
    });
  }

  ngOnInit() {
  }

  setLeasingTerm(term) {
    this.leasingTerm = term;
    this.params.term = term;
  }

  setParam(obj) {
    this.filterForm.get(obj.inputName).setValue(obj.item.id);
    this.params.user_type = obj.item.id;
  }

  changeAmount(event) {
    this.params.amount = event.target.value;
  }

  submit() {

  }
}
