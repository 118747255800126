import {Component, Input, OnInit} from '@angular/core';
import {PopupService} from '../../../../services/popup.service';

@Component({
  selector: 'app-popup-success',
  templateUrl: './popup-success.component.html',
  styleUrls: ['./popup-success.component.scss']
})

export class PopupSuccessComponent implements OnInit {

  @Input() popupParams;

  constructor(
    public popupService: PopupService,
  ) {}

  ngOnInit() {
  }
}
