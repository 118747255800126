// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// commands
import SomeCheckFormDataCommand from '../app/shared/SDK/Commands/SomeCheckFormDataCommand';

// endpoints
import CarEndpoint from '../app/shared/SDK/endpoints/CarEndpoint';
import GetCarsCommand from '../app/shared/SDK/Commands/GetCarsCommand';
import GetTransmissionsCommand from '../app/shared/SDK/Commands/GetTransmissionsCommand';
import GetModelsCommand from '../app/shared/SDK/Commands/GetModelsCommand';
import GetBrandsCommand from '../app/shared/SDK/Commands/GetBrandsCommand';
import GetTypeFuelsCommand from '../app/shared/SDK/Commands/GetTypeFuelsCommand';
import GetTypeOfDrivesCommand from '../app/shared/SDK/Commands/GetTypeOfDrivesCommand';
import GetTypeBodiesCommand from '../app/shared/SDK/Commands/GetTypeBodiesCommand';
import ListEndpoint from '../app/shared/SDK/endpoints/ListEndpoint';
import AllListCommand from '../app/shared/SDK/Commands/AllListCommand';
import CountListCommand from '../app/shared/SDK/Commands/CountListCommand';

export const environment = {
  production: false,
  defaultLanguage: 'ru',
  api: {
    host: 'https://dev.zet.finance/api',
    commands: [
      SomeCheckFormDataCommand,
      GetCarsCommand,
      GetTransmissionsCommand,
      GetModelsCommand,
      GetBrandsCommand,
      GetTypeFuelsCommand,
      GetTypeOfDrivesCommand,
      GetTypeBodiesCommand,
      AllListCommand,
      CountListCommand,
    ],
    endpoints: {
      carEndpoint: CarEndpoint,
      listEndpoint: ListEndpoint,
    },
    useFakeBackend: false,
    useAutotestMode: true,
    logCommands: true,  // show in browser's console log request-answer commands
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
