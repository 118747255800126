import {Component, OnInit} from '@angular/core';
import {PopupService} from '../../../../services/popup.service';

@Component({
  selector: 'app-advantages-conditions',
  templateUrl: './advantages-conditions.component.html',
  styleUrls: ['./advantages-conditions.component.scss']
})

export class AdvantagesConditionsComponent implements OnInit {

  constructor(
    public popupService: PopupService,
  ) {}

  ngOnInit() {}
}
