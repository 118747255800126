import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-advantages-list',
  templateUrl: './advantages-list.component.html',
  styleUrls: ['./advantages-list.component.scss']
})

export class AdvantagesListComponent implements OnInit {

  public listAdvantages: any[] = [];

  constructor() {}

  ngOnInit() {
    const total = 6;
    for (let i = 0; i < total; i++) {
      this.listAdvantages.push(0);
    }
  }
}
