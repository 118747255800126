import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CurrencyService {
  static availableCurrencies = ['UAH', 'EUR', 'USD'];

  protected currencyData = '';

  constructor() {
    if (localStorage.getItem('currency')) {
      this.currencyData = localStorage.getItem('currency');
    } else {
      this.currency = 'UAH';
    }
  }

  get currency() {
    return this.currencyData;
  }

  set currency(currency) {
    currency = currency.toUpperCase();

    if (CurrencyService.availableCurrencies.indexOf(currency) == -1) {
      return;
    }

    localStorage.setItem('currency', currency);
    this.currencyData = currency;
  }
}
