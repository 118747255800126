import {Endpoint} from './Endpoint';
import GetModelsCommand from '../Commands/GetModelsCommand';
import GetBrandsCommand from '../Commands/GetBrandsCommand';
import GetTransmissionsCommand from '../Commands/GetTransmissionsCommand';
import GetTypeBodiesCommand from '../Commands/GetTypeBodiesCommand';
import GetTypeFuelsCommand from '../Commands/GetTypeFuelsCommand';
import GetTypeOfDrivesCommand from '../Commands/GetTypeOfDrivesCommand';
import AllListCommand from '../Commands/AllListCommand';
import CountListCommand from '../Commands/CountListCommand';
import {Brand} from '../../types/brand';

export default class ListEndpoint extends Endpoint {

  /**
   * возвращает весь список
   */
  public getModels(params) {
    return this.sdk.triggerCommand(GetModelsCommand, params);
  }

  /**
   * возвращает весь список
   */
  public getBrands(params): Promise<Brand[]> {
    return this.sdk.triggerCommand(GetBrandsCommand, params);
  }

  /**
   * возвращает список топ брендов
   */
  public getBrandsOnlyMain(params: { only_main?: true, offset?: number, limit?: number }): Promise<Brand[]> {
    params.only_main = true;
    return this.sdk.triggerCommand(GetBrandsCommand, params);
  }

  /**
   * возвращает весь список
   */
  public getTransmissions() {
    return this.sdk.triggerCommand(GetTransmissionsCommand);
  }

  /**
   * возвращает весь список
   */
  public getTypeBodies() {
    return this.sdk.triggerCommand(GetTypeBodiesCommand);
  }

  /**
   * возвращает весь список
   */
  public getTypeFuels() {
    return this.sdk.triggerCommand(GetTypeFuelsCommand);
  }

  /**
   * возвращает весь список
   */
  public getTypeOfDrives() {
    return this.sdk.triggerCommand(GetTypeOfDrivesCommand);
  }

  /**
   * возвращает весь список данных(model, brand, transmission, typeBody, typeFuel, typeOfDrive) для фильтров
   *
   * @param list [model, brand, transmission, typeBody, typeFuel, typeOfDrive]
   */
  public all(list: {list?: string[]}) {
    return this.sdk.triggerCommand(AllListCommand, list);
  }

  public getCount() {
    return this.sdk.triggerCommand(CountListCommand);
  }
}
