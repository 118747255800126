import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import emailMask from 'text-mask-addons/dist/emailMask'
import {MessageService} from '../../../services/message.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})

export class ContactUsComponent implements OnInit {
  public emailMask = emailMask;
  public feedbackForm: FormGroup;
  public submitted = false;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.feedbackForm = this.fb.group({
      surname: ['', [Validators.required]],
      email: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]]
    });
  }

  sendMessage() {
    console.log(this.feedbackForm.controls);
    this.submitted = true;
    if (this.surname.invalid) this.surname.markAsTouched();
    if (this.feedbackForm.status === 'VALID') {
      this.submitted = false;
      this.messageService.sendMessage(this.feedbackForm.controls, 'contactUs');
      this.feedbackForm.reset();
    }
  }

  get f() {
    return this.feedbackForm.controls;
  }

  get surname() {
    return this.feedbackForm.get('surname');
  }

  get email() {
    return this.feedbackForm.get('email');
  }

  get subject() {
    return this.feedbackForm.get('subject');
  }

  get message() {
    return this.feedbackForm.get('message');
  }
}
