import {Moment} from 'moment';
import {RelationsInterface} from '../SDK/Interfaces/RelationsInterface';
import {DateTimesSupportInterface} from '../SDK/Interfaces/DateTimesSupportInterface';
import {Model} from './model';
import {TypeTransport} from './typeTransport';
import {TypeFuel} from './typeFuel';
import {TypeOfDrive} from './typeOfDrive';
import {TypeBody} from './typeBody';
import {Condition} from './condition';
import {Transmission} from './transmission';

export class Car implements RelationsInterface, DateTimesSupportInterface {
  id: number;
  slug: string;
  power: number;
  cost: number;    // цена
  amount?: number; // per month pay
  prepay?: number; // аванс
  availability: boolean;
  year_of_issue: Moment;
  engine_volume: number;
  fuel_consumption: number;
  full_description: string;
  modification: string;
  transmission_id: number;
  model?: Model;
  typeTransport?: TypeTransport;
  fuel?: TypeFuel;
  type_of_drive?: TypeOfDrive;
  type_body?: TypeBody;
  condition?: Condition;
  transmission?: Transmission;
  color: string;

  dateCastingFields(): string[] {
    return ['year_of_issue'];
  }

  timeCastingFields(): string[] {
    return [];
  }

  dateTimeCastingFields(): string[] {
    return [];
  }

  public relations(): { [p: string]: any } {
    return {
      model: Model,
      typeTransport: TypeTransport,
      fuel: TypeFuel,
      typeOfDrive: TypeOfDrive,
      typeBody: TypeBody,
      condition: Condition,
      transmission: Transmission,
    };
  }
}
