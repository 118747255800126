import {RelationsInterface} from '../Interfaces/RelationsInterface';
import {ValidationErrors} from '@angular/forms';
import {isNull} from 'util';

export abstract class BasicResponse implements RelationsInterface {
  data?: any;
  success?: string;

  abstract relations(): { [key: string]: any; };
}
