import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import swal from 'sweetalert2';

@Injectable()

export class PopupService {

  showPopupEvent: Subject<object> = new Subject();
  public dropOpenedId: string;
  public currentDropOpenedId: string;
  public dontClose = false; // флаг, который единоразово запрещает закрывать текущий дропдаун

  constructor() {}

  showPopup(popupName, params = null) {
    this.showPopupEvent.next({popupName, params});
  }

  closePopup() {
    swal.close();
  }

  showDropdown(id) {
    setTimeout(() => {
      // console.log(this.currentDropOpenedId +"!=="+ id);
      if (this.currentDropOpenedId !== id) {
        this.dropOpenedId = id;
        this.currentDropOpenedId = id;
      } else { // закрыть открытый дропдаун при повторном клике
        this.dropOpenedId = '';
        this.currentDropOpenedId = '';
      }
    }, 10);
  }

  closeDropdown() {
    if (!this.dontClose) {
      this.currentDropOpenedId = this.dropOpenedId;
      this.dropOpenedId = '';
    } else {
      this.dontClose = false;
    }
  }
}
