import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {CurrencyService} from "../services/currency.service";

/*
 * Add currency symbol to number.
 * Usage:
 *   priceValue | currencySymbol:(true/false)
 * Example:
 *   {{ 123 | currencySymbol }}
 *   formats to: 123 €
 *
 *   {{ 123 | currencySymbol:true }}
 *   formats to: 123 EUR
*/
@Pipe({name: 'currencySymbol'})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(public currencyService: CurrencyService) {
  }

  transform(data: any, showCode: boolean = false): string {
    let key = this.currencyService.currency.toUpperCase();

    return data + ' ' + (showCode ? key : getCurrencySymbol(key, 'narrow'));
  }
}
