import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PopupService} from '../../../../services/popup.service';

@Component({
  selector: 'app-popup-consulting',
  templateUrl: './popup-consulting.component.html',
  styleUrls: ['./popup-consulting.component.scss']
})

export class PopupConsultingComponent implements OnInit {

  public popupForm: FormGroup;
  public submitted = false;

  constructor(
    public popupService: PopupService,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.popupForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
    });
  }

  sendMessage() {
    this.submitted = true;
    if (this.popupForm.status === 'VALID') {
      this.submitted = false;
      this.popupService.closePopup();
      setTimeout(() => {
        this.popupService.showPopup('fail');
      }, 350);
    }
  }

  get f() {
    return this.popupForm.controls;
  }

  get name() {
    return this.popupForm.get('name');
  }

  get phone() {
    return this.popupForm.get('phone');
  }
}
