import {ApiService} from "../api.service";
import {EndpointInterface} from "../Interfaces/EndpointInterface";

interface EndpointMap {
    [key: string]: EndpointInterface
}

export class EndpointBus {
    /**
     * Holds all commands.
     */
    protected endpoints: EndpointMap = {
    };

    /**
     * Holds all command names.
     */
    protected endpoint_names: Array<string> = [];

    /**
     * Api instance
     */
    protected sdk: ApiService;

    /**
     * Instantiate Command Bus.
     */
    public constructor($sdk: ApiService)
    {
        this.sdk = $sdk;
        let names = Object.getOwnPropertyNames(this.sdk.getConfig('endpoints'));

        for (let i = 0; i < names.length; i++) {
            let name = names[i];
            this.addEndpoint(name, this.sdk.getConfig('endpoints')[name]);
        }
    }

    /**
     * Returns the list of commands.
     */
    public getEndpoints(): EndpointMap
    {
        return this.endpoints;
    }

    public getEndpoint(name: string): EndpointInterface {
        return this.endpoints[name];
    }

    /**
     * Add a command to the commands list.
     *
     * @param name
     * @param endpoint
     */
    public addEndpoint(name: string, endpoint: EndpointInterface): this
    {
        endpoint = this.resolveEndpointObject(endpoint);

        if (this.endpoint_names.indexOf(name) >= 0) {
            throw Error('Command with this name ' + name + ' is already exists.');
        } else {
            this.endpoint_names.push(name);
            this.sdk[name] = endpoint;
        }

        this.endpoints[name] = endpoint;

        return this;
    }

    /**
     * @return object
     */
    private resolveEndpointObject($endpoint)
    {
        if (typeof $endpoint != 'function') {
            throw new Error('Endpoint class "' + ($endpoint) + '" not found! Please make sure the class exists.');
        } else {
            $endpoint = new $endpoint(this.sdk);
        }

        return $endpoint;
    }
}
