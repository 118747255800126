import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ScrollService {

  public scrollValue = 0;

  constructor() {}

  onScrollChanges(scrollValue) {
    this.scrollValue = scrollValue;
  }
}
