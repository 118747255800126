import {Routes} from '@angular/router';
import {NotFoundComponent} from './not-found/not-found.component';
import {CheckApiComponent} from './check-api/check-api.component';
import {IndexComponent} from './index/index.component';
import {CatalogListComponent} from './catalog-list/catalog-list.component';
import {CatalogItemComponent} from './catalog-item/catalog-item.component';
import {AboutComponent} from './about/about.component';
import {AdvantagesComponent} from './advantages/advantages.component';
import {DealersComponent} from './dealers/dealers.component';
import {DocsComponent} from './docs/docs.component';
import {OffersListComponent} from './offers-list/offers-list.component';
import {Error404Component} from '../shared/components/error404/error404.component';
import {OffersItemComponent} from './offers-item/offers-item.component';
import {IndividualComponent} from './individual/individual.component';
import {CatalogItemComplectationComponent} from './catalog-item/_parts/catalog-item-complectation/catalog-item-complectation.component';
import {CatalogItemReserveComponent} from './catalog-item/_parts/catalog-item-reserve/catalog-item-reserve.component';
import {EquipmentComponent} from './equipment/equipment.component';

export const routes: Routes = [
  { // группа авторизация
    path: '', // префикс
    component: IndexComponent,  // базовый слой
  },
  {
    path: 'check-api',
    component: CheckApiComponent
  },
  {
    path: 'catalog',
    children: [
      {path: '', component: CatalogListComponent},
      {path: ':brandSlug', component: CatalogListComponent},
      {path: ':brandSlug/:modelSlug', component: CatalogItemComponent},
      {path: ':brandSlug/:modelSlug/:complectSlug', component: CatalogItemComplectationComponent},
      {path: ':brandSlug/:modelSlug/:complectSlug/reserve', component: CatalogItemReserveComponent},
      {path: '**', component: NotFoundComponent}
    ]
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'advantages',
    component: AdvantagesComponent
  },
  {
    path: 'dealers',
    component: DealersComponent
  },
  {
    path: 'docs',
    component: DocsComponent
  },
  {
    path: 'equipment',
    component: EquipmentComponent
  },
  {
    path: 'offers',
    children: [
      {path: '', component:  OffersListComponent},
      {path: ':ref', component: OffersItemComponent}
    ]
  },
  {
    path: 'individual',
    component: IndividualComponent
  },
  {
    path: '404',
    component: Error404Component
  },
  {
    path: '500',
    component: Error404Component
  },
  {
    path: '**',
    redirectTo: '404'
  }
];
