import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog-item-title',
  templateUrl: './catalog-item-title.component.html',
  styleUrls: ['./catalog-item-title.component.scss']
})
export class CatalogItemTitleComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
