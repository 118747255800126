import { AxiosResponse } from 'axios';
import ApiError from './ApiError';



export default class BasicApiError extends ApiError {
    public error: object;

    constructor(serverResponse: AxiosResponse, errorsSummary: object) {
        super(serverResponse);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = 'BasicApiError';
        this.error = errorsSummary;
    }
}

