import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiService} from '../../shared/SDK/api.service';
import {Car} from '../../shared/types/car';
import {ScrollService} from '../../services/scroll.service';
import { Model } from '../../shared/types/model';

@Component({
  selector: 'app-catalog-item',
  templateUrl: './catalog-item.component.html',
  styleUrls: ['./catalog-item.component.scss']
})

export class CatalogItemComponent implements OnInit {
  cars: Car[] = [];
  modifications: string[] = [];
  modificationGroupedCars: { [key: string]: Car[]; } = {};
  model: Model;

  constructor(
    private activatedRoute: ActivatedRoute,
    private api: ApiService,
    public scrollServices: ScrollService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params: ParamMap) => {
      if (params.has('brandSlug') && params.has('modelSlug')) {
        const brandSlug = params.get('brandSlug');
        const modelSlug = params.get('modelSlug');
        console.log('ParamMap', brandSlug, modelSlug);
        this.api.carEndpoint
          .search({model: modelSlug})
          .then(data => this.cars = data)
          .then(data => {
            const firstCar = data[0] || false;
            if (!firstCar) {
              return this.router.navigate(['/catalog']);
            }
            this.model = data[0].model;
            this.modifications = [];
            this.modificationGroupedCars = {};
            data.forEach((car: Car) => {  // TODO: sorting
              if (this.modifications.indexOf(car.modification) === -1) {
                this.modifications.push(car.modification);
                this.modificationGroupedCars[car.modification] = [];
              }
              this.modificationGroupedCars[car.modification].push(car);
            });
            console.log('modificationGroupedCars: ', this.modificationGroupedCars);
          });
      } else {
        // TODO: go to 404
      }
    });
  }
}
