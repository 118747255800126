import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as Raven from 'raven-js';

import { environment } from '../environments/environment';
import { routes } from './routes/root';
import { CheckApiComponent } from './routes/check-api/check-api.component';
import { AuthService } from './services/auth.service';
import { ApiService } from './shared/SDK/api.service';
import { SampleFormComponent } from './routes/sample-form/sample-form.component';
import { NotFoundComponent } from './routes/not-found/not-found.component';
import { LoginComponent } from './routes/login/login.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { HeaderLayoutComponent } from './layout/header-layout/header-layout.component';
import { FooterLayoutComponent } from './layout/footer-layout/footer-layout.component';
import { IndexComponent } from './routes/index/index.component';
import { CatalogListIndexComponent } from './routes/index/_parts/catalog-list-index/catalog-list-index.component';
import { TopOfferComponent } from './routes/index/_parts/top-offer/top-offer.component';
import { SpecialOffersComponent } from './routes/index/_parts/special-offers/special-offers.component';
import { ContactUsComponent } from './shared/components/contact-us/contact-us.component';
import { CatalogListComponent } from './routes/catalog-list/catalog-list.component';
import { CatalogItemComponent } from './routes/catalog-item/catalog-item.component';
import { AboutComponent } from './routes/about/about.component';
import { AdvantagesComponent } from './routes/advantages/advantages.component';
import { AdvantagesListComponent } from './routes/advantages/_parts/advantages-list/advantages-list.component';
import { AdvantagesConditionsComponent } from './routes/advantages/_parts/advantages-conditions/advantages-conditions.component';
import { AdvantagesFaqComponent } from './routes/advantages/_parts/advantages-faq/advantages-faq.component';
import { CatalogItemSliderComponent } from './routes/catalog-item/_parts/catalog-item-slider/catalog-item-slider.component';
import { CatalogItemFiltersComponent } from './routes/catalog-item/_parts/catalog-item-filters/catalog-item-filters.component';
import { CatalogItemPropertiesComponent } from './routes/catalog-item/_parts/catalog-item-properties/catalog-item-properties.component';
import { HelpComponent } from './shared/components/help/help.component';
import { CatalogItemCalculateComponent } from './routes/catalog-item/_parts/catalog-item-calculate/catalog-item-calculate.component';
import { CatalogItemReserveComponent } from './routes/catalog-item/_parts/catalog-item-reserve/catalog-item-reserve.component';
import { CatalogListFiltersComponent } from './routes/catalog-list/_parts/catalog-list-filters/catalog-list-filters.component';
import { DealersComponent } from './routes/dealers/dealers.component';
import { DocsComponent } from './routes/docs/docs.component';
import { DocsPersonsComponent } from './routes/docs/_parts/docs-persons/docs-persons.component';
import { DocsExamplesComponent } from './routes/docs/_parts/docs-examples/docs-examples.component';
import { OffersListComponent } from './routes/offers-list/offers-list.component';
import { SendQuestionComponent } from './shared/components/send-question/send-question.component';
import { HeaderBottomComponent } from './layout/header-layout/_parts/header-bottom/header-bottom.component';
import { Error404Component } from './shared/components/error404/error404.component';
import { CatalogListModelsComponent } from './routes/catalog-list/_parts/catalog-list-models/catalog-list-models.component';
import { CatalogItemTitleComponent } from './routes/catalog-item/_parts/catalog-item-title/catalog-item-title.component';
import { OffersItemComponent } from './routes/offers-item/offers-item.component';
import { HeaderSearchComponent } from './routes/index/_parts/header-search/header-search.component';
import { ScrollDirective } from './directives/scroll.directive';
import { WINDOW_PROVIDERS} from './services/window.service';
import { OnlyDigitsDirective } from './directives/only-digits.directive';
import { NgxMaskModule } from 'ngx-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { IndividualComponent } from './routes/individual/individual.component';
import { IndividualFormComponent } from './routes/individual/_parts/individual-form/individual-form.component';
import { OwlModule} from 'ngx-owl-carousel';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { CatalogItemComplectationComponent } from './routes/catalog-item/_parts/catalog-item-complectation/catalog-item-complectation.component';
import { SearchBrandsComponent } from './shared/components/search-brands/search-brands.component';
import { IndividualPanelComponent } from './shared/components/individual-panel/individual-panel.component';
import { TranslateCompiler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { CurrencyService } from './services/currency.service';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { PricePipe } from './pipes/price.pipe';
import { PopupConsultingComponent } from './shared/components/popups/popup-consulting/popup-consulting.component';
import { PopupGeneralComponent } from './shared/components/popups/popup-general/popup-general.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PopupService } from './services/popup.service';
import { PopupSuccessComponent } from './shared/components/popups/popup-success/popup-success.component';
import { PopupFailComponent } from './shared/components/popups/popup-fail/popup-fail.component';
import { NgSelectComponent } from './shared/components/ng-select/ng-select.component';
import { MessageService } from './services/message.service';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AdvantagesTextComponent } from './routes/advantages/_parts/advantages-text/advantages-text.component';
import { EquipmentComponent } from './routes/equipment/equipment.component';

// данная функция необходима при AOT компиляции
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

Raven
  .config('https://25ad3cf162c44bf298536e8bf22bf3f3@sentry.common.yttm.work/8')
  .install();

export class RavenErrorHandler implements ErrorHandler {
  handleError(err:any) : void {
    Raven.captureException(err.originalError || err);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    BasicLayoutComponent,
    LoginLayoutComponent,
    HeaderLayoutComponent,
    FooterLayoutComponent,
    NotFoundComponent,
    CheckApiComponent,
    SampleFormComponent,
    IndexComponent,
    CatalogListIndexComponent,
    TopOfferComponent,
    SpecialOffersComponent,
    ContactUsComponent,
    CatalogListComponent,
    CatalogItemComponent,
    AboutComponent,
    AdvantagesComponent,
    AdvantagesListComponent,
    AdvantagesConditionsComponent,
    AdvantagesFaqComponent,
    CatalogItemSliderComponent,
    CatalogItemFiltersComponent,
    CatalogItemPropertiesComponent,
    HelpComponent,
    CatalogItemCalculateComponent,
    CatalogItemReserveComponent,
    CatalogListFiltersComponent,
    DealersComponent,
    DocsComponent,
    DocsPersonsComponent,
    DocsExamplesComponent,
    OffersListComponent,
    SendQuestionComponent,
    HeaderBottomComponent,
    Error404Component,
    CatalogListModelsComponent,
    CatalogItemTitleComponent,
    OffersItemComponent,
    HeaderSearchComponent,
    ScrollDirective,
    OnlyDigitsDirective,
    IndividualComponent,
    IndividualFormComponent,
    LoaderComponent,
    CatalogItemComplectationComponent,
    SearchBrandsComponent,
    IndividualPanelComponent,
    CurrencySymbolPipe,
    PricePipe,
    NgSelectComponent,
    PopupConsultingComponent,
    PopupGeneralComponent,
    PopupSuccessComponent,
    PopupFailComponent,
    ClickOutsideDirective,
    AdvantagesTextComponent,
    EquipmentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      }
    }),
    AppRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,  // для билдера форм
    NgxMaskModule.forRoot(),
    TextMaskModule,
    OwlModule,
    SweetAlert2Module.forRoot(),
    RouterModule.forRoot(
      routes,
      { enableTracing: !environment.production } // <-- debugging purposes only
    )
  ],
  providers: [
    AuthService,
    CurrencyService,
    ApiService,
    PopupService,
    WINDOW_PROVIDERS,
    MessageService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
