import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-advantages-faq',
  templateUrl: './advantages-faq.component.html',
  styleUrls: ['./advantages-faq.component.scss']
})

export class AdvantagesFaqComponent implements OnInit {

  public listQuestions: any[] = [];
  public isQuestionOpened: any[] = [];

  constructor() {}

  ngOnInit() {
    const totalBlocks = 4;
    const totalQuestions = [4, 2, 1, 1];
    for (let i = 0; i < totalBlocks; i++) {
      this.listQuestions[i] = [];
      this.isQuestionOpened[i] = [];
      for (let k = 0; k < totalQuestions[i]; k++) {
        this.listQuestions[i].push(0);
      }
    }
  }

  showFaq(groupId, questionId) {
    this.isQuestionOpened[groupId][questionId] = !this.isQuestionOpened[groupId][questionId];
  }
}
