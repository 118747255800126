import {BasicResponse} from './BasicResponse';

/**
 *
 */
export default class SomeCheckFormDataResponse extends BasicResponse {
  data?: any;
  success: string;

  public relations(): { [p: string]: any } {
    return {
    };
  }
}
