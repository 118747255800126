import {Command} from './Command';

export default class AllListCommand extends Command {
    name = 'list/all';

    usesLanguage = true;

    // public handle($arguments: any): Promise<SomeCheckFormDataResponse> {
    public handle($arguments: {list?: string[]}): Promise<any[]> {
        console.log('AllListCommand->handle');

        // return this.apiResponse<SomeCheckFormDataResponse>('get', this.name, $arguments, SomeCheckFormDataResponse);
        return this.apiResponse('get', this.name, $arguments);
    }

    protected fake(method: string, $arguments: any): Promise<any> {
      return Promise.resolve({
        success: true,
        data: [
        ]
      });

      return Promise.reject({
          success: false,
          error: {
              code: 'nG12',
              note: 'Слишком много ошибок',
              fields: {
                  height: 'Высота паллеты не может быть менее 50 см',
                  townSender: 'Мы сюда не возим, это ж, блин, Рио-Де-Жанейро!!',
                  tarif: 'Неправильно выбран тариф',
                  weight: 'Нельзя по украинским дорогам возить груз свыше 202 тонн'
              }
          }
      });
    }
}
