import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../services/scroll.service';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss']
})

export class DocsComponent implements OnInit {

  constructor(
    public scrollServices: ScrollService,
  ) {}

  ngOnInit() {
  }
}
