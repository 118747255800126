import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../services/scroll.service';

@Component({
  selector: 'app-offers-list',
  templateUrl: './offers-list.component.html',
  styleUrls: ['./offers-list.component.scss']
})

export class OffersListComponent implements OnInit {

  constructor(
    public scrollServices: ScrollService,
  ) {}

  ngOnInit() {
  }
}
