import {BasicResponse} from './BasicResponse';
import {Brand} from "../../types/brand";
import {Model} from "../../types/model";

/**
 *
 */
export default class GetModelsResponse extends BasicResponse {
  data?: any;
  success: string;

  public relations(): { [p: string]: any } {
    return {
      'data[]': Model
    };
  }
}
