import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-advantages-text',
  templateUrl: './advantages-text.component.html',
  styleUrls: ['./advantages-text.component.scss']
})
export class AdvantagesTextComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
