import ApiSDKError from './ApiSDKError';
import {AxiosResponse} from 'axios';
import {BasicResponse} from '../responses/BasicResponse';
import {isNull} from 'util';
import {ValidationErrors} from '@angular/forms';

export default class ApiError extends ApiSDKError {
    public serverResponse: AxiosResponse;
    public basicResponse: BasicResponse = null;

    constructor(serverResponse: AxiosResponse) {
        super();
        if (serverResponse.data && serverResponse.data.error && serverResponse.data.error.code) {
          console.error(`API returned a HTTP ${serverResponse.data.error.code || undefined} error code with success:${serverResponse.data.success}`);
        }
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = 'ApiError';
        const reducedServerResponse = { ...serverResponse };
        // Reduce logging by removing the 'enumerable' flag on some keys in AxiosResponse.
        Object.defineProperties(
            reducedServerResponse,
            {
                config: { enumerable: false },
                data: { enumerable: false },
                headers: { enumerable: false },
                request: { enumerable: false }
            }
        );
        this.serverResponse = reducedServerResponse;
    }

    /**
     * Если АПИ вернуло "все ок" удаляем свои добавленные ошибки.
     */
    static clearErrors(errors, fieldName = ''): object|null {
        if (errors === null) {
            return errors;
        }

        if (errors['_keys'] === undefined) {
            return errors;
        }

        for (const value of errors['_keys']) {
            if (fieldName.length === 0 && errors[value] !== undefined) {
                delete errors[value];
            }
            if (fieldName.length > 0 && errors[fieldName] !== undefined) {
                delete errors[value];
            }
        }
        return errors;
    }

    static handleErrors(fields: object, fieldName = ''): object|null {
      const tmp = {};
      let isErrorsPresented = false;

      Object.keys(fields).map((key, index) => {
        if (fieldName.length > 0 && key !== fieldName) {
          return;
        }

        tmp[key] = fields[key];
        if (tmp['_keys'] === undefined) {
          tmp['_keys'] = [key];
          isErrorsPresented = true;
        } else {
          tmp['_keys'].push(key);
          isErrorsPresented = true;
        }
      });

      return isErrorsPresented ? tmp : null;
    }

    public toString() {
        let result = '';

        // ERROR MESSAGE FROM API
        // success:false,
        // error: {
        //  text?,
        //  note?,
        //  code?,
        //  fields: {
        //      fieldKey: fieldErrorText,
        //      fieldKey2: fieldErrorText2
        //  }
        // }
        if (typeof this['error'] === 'object') {
            const tmpErr = this['error'];
            if (typeof tmpErr['text'] === 'string') {
                result += tmpErr['text'] + '\r\n';
            }
            if (typeof tmpErr['note']) {
                result += (result.length > 0 ? ':' : '') + tmpErr['note'] + '\r\n';
            }

            if (typeof this['error']['fields'] === 'object') { // FORM VALIDATION API success:false, data: {...}
                Object.keys(this['error']['fields']).forEach((key) => {
                    result += this['error']['fields'][key] + '\r\n';
                });
            }

            result = result.length === 0 ? JSON.stringify(this['error']) : result;
        } else {
            result = this.message;
        }

        return result;
    }

    /**
     * Преобразовать ошибки полей от АПИ к ошибкам Валидатора Angular.
     * Запоминает какие поля добавили для последующей очистки (если другие ошибки имеются).
     */
    map2ValidationErrors(errors: ValidationErrors | null, fieldName = ''): object|null {
      if (typeof this['error']['fields'] === 'undefined') {
          return null;
      }

      const tmp = ApiError.handleErrors(this['error']['fields'], fieldName);

      if (isNull(errors) || typeof errors['concat'] !== 'function') {
        return tmp;
      }

      return errors.concat(tmp);
    }
}
