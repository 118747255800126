import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../services/scroll.service';

@Component({
  selector: 'app-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss']
})

export class AdvantagesComponent implements OnInit {

  constructor(
    public scrollServices: ScrollService,
  ) {}

  ngOnInit() {
  }
}
