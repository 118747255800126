import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PopupService} from '../../../../services/popup.service';
import {SwalComponent, SwalPortalTargets} from '@sweetalert2/ngx-sweetalert2';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-popup-general',
  templateUrl: './popup-general.component.html',
  styleUrls: ['./popup-general.component.scss']
})

export class PopupGeneralComponent {

  public popupName: string;
  public popupParams;
  public subscriptionPopup: Subscription;

  @Input() timeoutId: any;
  @Output() onClose = new EventEmitter<string>();
  @ViewChild('popupGeneral', {static: false}) private popupGeneral: SwalComponent;

  constructor(
    private popupService: PopupService,
    public readonly swalTargets: SwalPortalTargets
  ) {
    if (!this.subscriptionPopup) {
      this.subscriptionPopup = popupService.showPopupEvent.subscribe( (data: any) => {
        setTimeout(() => { // Timeout ставим, чтоб успели подгрузиться все данные в переменные попапа и попап не был пустым
          if (data.popupName) {
            this.popupName = data.popupName;
            this.popupParams = data.params;
            this.popupGeneral.fire();
          } else {
            this.popupService.closePopup();
          }
        }, 10);
      });
    }
  }
}

