import {Component, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from '../../../../shared/SDK/api.service';
import {Brand} from '../../../../shared/types/brand';
import {FilterService} from '../../../../services/filter.service';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import {CurrencyService} from '../../../../services/currency.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss']
})

export class HeaderSearchComponent implements OnInit {

  public filterForm: FormGroup;
  public sumPattern = '[0-9]*';
  public selectedBrand: Brand = null;
  public priceMask = {
    mask: createNumberMask({
      prefix: '',
      suffix: '', // This will put the euro sign at the end, with a space.
      thousandsSeparatorSymbol: ' ',
      allowDecimal: true,
      modelClean: true
    })
  };
  public Search;

  @Input() brands: Brand[];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private sdk: ApiService,
    private filerService: FilterService,
    public currencyService: CurrencyService,
  ) {
    this.filterForm = fb.group({
      brand: fb.control(''),
      amount: fb.control('')
    });
  }

  async ngOnInit() {
  }

  submit() {
    if (this.filterForm.value.brand) {
      this.selectedBrand = this.brands.find((b) => {
        return b.slug === this.filterForm.value.brand;
      });
    }
    this.filerService.setFilters({amount: this.unMask(this.filterForm.value.amount)});
    this.router.navigateByUrl('/catalog/' + (this.selectedBrand ? this.selectedBrand.slug : ''));
  }

  unMask(value) {
    return Number(value.replace(/[^\d.-]/g, '', ''));
  }
}
