import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Car} from '../../../../shared/types/car';
import {Router} from '@angular/router';

@Component({
  selector: 'app-catalog-item-properties',
  templateUrl: './catalog-item-properties.component.html',
  styleUrls: ['./catalog-item-properties.component.scss']
})

export class CatalogItemPropertiesComponent implements OnInit, OnChanges {
  @Input() modificationGroupedCars: { [key: string]: Car[]; };
  modificationsLoaded = false;

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.modificationGroupedCars && Object.keys(this.modificationGroupedCars).length > 0) {
      this.modificationsLoaded = true;
    }
  }
}
