import {Command} from './Command';

export default class CountListCommand extends Command {
    name = 'list/count';

    usesLanguage = true;

    // public handle($arguments: any): Promise<SomeCheckFormDataResponse> {
    public handle($arguments): Promise<any[]> {
        console.log('CountListCommand->handle');

        // return this.apiResponse<SomeCheckFormDataResponse>('get', this.name, $arguments, SomeCheckFormDataResponse);
        return this.apiResponse('get', this.name, $arguments);
    }

    protected fake(method: string, $arguments: any): Promise<any> {
      return Promise.resolve({
        success: true,
        data: [
        ]
      });

      return Promise.reject({
          success: false,
          error: {
              code: 'nG12',
              note: 'Errors',
          }
      });
    }
}
