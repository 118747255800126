import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Brand} from '../shared/types/brand';
import {Model} from '../shared/types/model';

interface CarFilters {
  brand?: Brand;
  model?: Model;
  amount?: number;
}

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filters = new BehaviorSubject<CarFilters>({
    brand: null,
    model: null,
    amount: null,
  });

  constructor() {
  }

  setFilters(payload: CarFilters) {
    this.filters.next({...this.getFilters(), ...payload});
  }

  getFilters$(): Observable<CarFilters> {
    return this.filters.asObservable();
  }

  getFilters(): CarFilters {
    return this.filters.getValue();
  }


}
