import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../shared/SDK/api.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params} from '@angular/router';
import CarEndpoint from '../../shared/SDK/endpoints/CarEndpoint';

@Component({
  selector: 'app-sample-form',
  templateUrl: './sample-form.component.html',
  styleUrls: ['./sample-form.component.scss']
})
export class SampleFormComponent implements OnInit {
  customerEditForm: FormGroup;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private route: ActivatedRoute
  ) { }

  // Getters
  get name() { return this.customerEditForm.get('name'); }
  get email() { return this.customerEditForm.get('email'); }
  get height() { return this.customerEditForm.get('height'); }

  ngOnInit() {
    // this.apiService.TownEndpoint.someCheck();
    this.createForm();
  }

  createForm() {
    this.customerEditForm = this.fb.group({
      name: [
        '',
        Validators.required
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email
        ]
      ],
      height: [
        '',
        [
          Validators.required
        ]
      ]
    });
    this.customerEditForm.controls['height'].setAsyncValidators(
      CarEndpoint.someCheckValidator(this.apiService, this.customerEditForm)
    );
  }

  /**
   * Если блокировать кнопку на pending то ее нельзя будет нажать пока происходит валидация или пока происходит отправка
   */
  onSubmit() {
    // в controls можно выбрать любое поле только при условии что в someCheckValidator мы отправляем всеравно все данные
    // сейчас это form.getRawValue() - все данные
    // Если кнопка блокируется в шаблоне по pending статусу тут можно ничего не проверять и считать что данные валидные, тогда достаточно добавить if (this.customerEditForm.invalid) { return }
    // Если кнопка блокируется в шаблоне по pending статусу и по this.customerEditForm.invalid тогда проверок на валидность можно не добавлять
    CarEndpoint.someCheckValidator(this.apiService, this.customerEditForm, false)(this.customerEditForm.controls['height'])
      .then((result) => {
        // api says all ok
        console.log('All ok', result);
      })
      .catch((error) => {
        // api says error, в форму функция someCheckValidator уже подставила ошибку
        console.error('someCheckValidator error', error);
      });
  }

}
