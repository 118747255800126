import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-individual-panel',
  templateUrl: './individual-panel.component.html',
  styleUrls: ['./individual-panel.component.scss']
})

export class IndividualPanelComponent implements OnInit {

  @Input() individualSelection: boolean;
  @Output() needsSetIndividualSelection = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit() {
  }

  hideIndividualSelection() {
    this.individualSelection = false;
    this.needsSetIndividualSelection.emit(true);
  }
}
