import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgOption} from '@ng-select/ng-select';
import {Brand} from '../../types/brand';
import {Model} from '../../types/model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ng-select',
  templateUrl: './ng-select.component.html',
  styleUrls: ['./ng-select.component.scss']
})

export class NgSelectComponent implements OnInit, OnChanges {

  public items: NgOption[] = [];
  public selectedRef;
  public selectedItem: any;

  @Input() parentForm: FormGroup;
  @Input() inpFormControlName: string;
  @Input() selectOptions: any[];

  @Output() needsSetParam = new EventEmitter<object>();

  constructor(
    private translateService: TranslateService,
  ) {}

  ngOnInit() {

    if (this.inpFormControlName === 'user_type') {
      this.items.push({id: 'person', description: this.translateService.instant('user_type.person')});
      this.items.push({id: 'fop', description: this.translateService.instant('user_type.fop')});
      this.items.push({id: 'entity', description: this.translateService.instant('user_type.entity')});
      this.selectedRef = 'person';
    }


    // this.onChange();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('NgSelectComponent ngOnChanges: ', changes);
    console.log('NgSelectComponent ngOnChanges: ', changes);
    if (changes.selectOptions && changes.selectOptions.currentValue) {
      const items = [];

      if (this.inpFormControlName === 'driveType') {
        items[0] = {id: 0, description: this.translateService.instant('type_of_driver.all')};
      }
      if (this.inpFormControlName === 'body') {
        items[0] = {id: 0, description: this.translateService.instant('body_type.all')};
      }
      if (this.inpFormControlName === 'transmission') {
        items[0] = {id: 0, description: this.translateService.instant('transmission.all')};
      }
      if (this.inpFormControlName === 'model') {
        items[0] = {id: 0, description: this.translateService.instant('condition.all')};
      }
      if (this.inpFormControlName === 'fuel') {
        items[0] = {id: 0, description: this.translateService.instant('type_fuel.any')};
      }

      for (const item of changes.selectOptions.currentValue) {
        items.push({id: item.id, description: item.description});
      }
      console.log('NgSelectComponent ngOnChanges push1: ', this.items);
      this.items = items;

      this.selectedRef = 0;
    }
  }

  onChange() {
    for (const i in this.items) {
      if (this.selectedRef === this.items[i].id) {
        this.selectedItem = this.items[i];
      }
    }
    this.needsSetParam.emit({inputName: this.inpFormControlName, item: this.selectedItem});
  }
}
