import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import {ApiService} from '../../shared/SDK/api.service';
import {Brand} from '../../shared/types/brand';
import {Model} from '../../shared/types/model';
import {ScrollService} from '../../services/scroll.service';
import { FilterService } from '../../services/filter.service';
import { Transmission } from '../../shared/types/transmission';
import { TypeBody } from '../../shared/types/typeBody';
import { TypeFuel } from '../../shared/types/typeFuel';
import { TypeOfDrive } from '../../shared/types/typeOfDrive';
import { Condition } from '../../shared/types/condition';
import { TranslateService } from '@ngx-translate/core';

class FilterModel {
  prepay?: number;
  limit?: number;
  page?: number;
  term?: number;
  user_type?: string;  // entity | person
  brand?: number| string; // id
  fuel?: number; // id
  condition?: number; // id
  driveType?: number; // id
  body?: number; // id
  transmission?: number; // id
  offset?: number;
}

@Component({
  selector: 'app-catalog-list',
  templateUrl: './catalog-list.component.html',
  styleUrls: ['./catalog-list.component.scss']
})
export class CatalogListComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private api: ApiService,
    public scrollServices: ScrollService,
    public filterService: FilterService,
    public translateService: TranslateService,
  ) {}
  selectedBrand: Brand = null;
  viewModels: Model[] = [];
  allModels: Model[] = [];
  modelToSelect: Model[];
  brands: Brand[] = [];
  transmissions: Transmission[];
  offset = 0;
  typeBody: TypeBody[];
  typeFuel: TypeFuel[];
  typeOfDrive: TypeOfDrive[];
  conditions: Condition[];

  static prepareFormDataToModel(data: object): object {
    const object = {};
    for (const field in data) {
      if (data[field] !== 'empty' && data[field] && data[field] != 0) {
        object[field] = data[field];
      }
    }

    return object;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      if (params.has('brandSlug')) {
        const slug = params.get('brandSlug');
        console.log('ParamMap', slug, params);

        this.selectedBrand = null;
        this.selectBrand(slug);
        this.setFilterValue();
      } else {
        console.log('without slug');
        this.selectedBrand = null;
        this.init();
      }
    });

    this.filterService.getFilters$().subscribe(value => {
      if (!this.selectedBrand && value.brand || value.brand && value.brand.slug !== this.selectedBrand.slug) {
        this.modelToSelect = this.allModels.filter(model => {
          return model.brand_id === value.brand.id;
        });
      } else {
        this.modelToSelect = this.allModels;
      }
      this.selectedBrand = value.brand;
    });
  }

  selectBrand(slug: string) {
    const req: {[key: string]: string | number} = {};
    this.api.listEndpoint
      .getBrands({})
      .then((data: Brand[]) => {
        if (data.length !== 1) {
          // TODO: go to 404 page
        }
        this.selectedBrand = data.filter(value => value.slug === slug)[0];
        this.brands = data;

        if (slug) {
          this.filterService.setFilters({brand: this.selectedBrand});
          req.brand = this.selectedBrand.slug;
        }

        if (this.filterService.getFilters().amount) {
          req.prepay = this.filterService.getFilters().amount;
        }

        this.loadModels(req);
      });
  }

  loadModels(filter: FilterModel) {
    if (filter.offset) {
      filter.offset = this.offset;
    }

    this.api.listEndpoint
        .getModels(filter)
        .then((data: Model[]) => {
          if (this.offset === 0) {
            this.viewModels = [];
          }
          this.viewModels.push(...data);
          if (this.filterService.getFilters().brand) {
            this.modelToSelect = [].concat(this.allModels.filter(model => {
              return model.brand_id === this.filterService.getFilters().brand.id;
            }));
          } else {
            this.modelToSelect = [].concat(this.allModels);
          }
        });
  }

  loadBrands() {
    this.api.listEndpoint
      .getBrands({})
      .then((data: Brand[]) => {
        this.brands = data;
        if (this.selectedBrand) {
          this.selectedBrand = this.brands.filter((b) => b.id == this.selectedBrand.id)[0];
        }
      });
  }

  startLoad(event) {
    this.offset = 0;
    console.log('todo loaded data', event);

    if (event.brand && event.model) {
      const model = this.allModels.filter((val) => val.id === event.model)[0];
      this.router.navigate([`/catalog/${event.brand}/${model.slug}`]);
    }

    this.loadModels(CatalogListComponent.prepareFormDataToModel(event));
  }

  loadMore() {
    this.offset += this.viewModels.length;
    this.loadModels({brand: this.selectedBrand.slug});
  }

  init() {
    this.loadBrands();
    this.loadModels({});
    this.setFilterValue();
  }

  setFilterValue() {
    this.api.listEndpoint
      .all({})
      .then((data) => {
        this.transmissions = data.transmission.map((val) => {
          val.description = this.translateService.instant('transmission.' + val.slug);
          return val;
        });

        this.typeBody = data.typeBody.map((val) => {
          val.description = this.translateService.instant('body_type.' + val.slug);
          return val;
        });

        this.typeFuel = data.typeFuel.map((val) => {
          val.description = this.translateService.instant('type_fuel.' + val.slug);
          return val;
        });

        this.typeOfDrive = data.typeOfDrive.map((val) => {
          val.description = this.translateService.instant('type_of_driver.' + val.slug);
          return val;
        });

        this.allModels = data.model;

        this.conditions = data.condition;
      });
  }
}
