import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FilterService} from '../../../../services/filter.service';
import {Brand} from '../../../../shared/types/brand';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ApiService} from '../../../../shared/SDK/api.service';
import {Model} from '../../../../shared/types/model';
import {createNumberMask} from 'text-mask-addons/dist/textMaskAddons';
import { Transmission } from '../../../../shared/types/transmission';
import { Condition } from '../../../../shared/types/condition';
import { TypeBody } from '../../../../shared/types/typeBody';
import { TypeFuel } from '../../../../shared/types/typeFuel';
import { TypeOfDrive } from '../../../../shared/types/typeOfDrive';
import {CurrencyService} from '../../../../services/currency.service';

@Component({
  selector: 'app-catalog-list-filters',
  templateUrl: './catalog-list-filters.component.html',
  styleUrls: ['./catalog-list-filters.component.scss']
})
export class CatalogListFiltersComponent implements OnInit, OnChanges {

  @Input() selectedBrand: Brand = null;
  @Input() brands: Brand[];
  @Input() models: Model[];
  @Input() transmissions: Transmission[];
  @Input() conditions: Condition[];
  @Input() typeBody: TypeBody[];
  @Input() typeFuel: TypeFuel[];
  @Input() typeOfDrive: TypeOfDrive[];

  @Output() filter = new EventEmitter();

  filterForm: FormGroup;
  amount: number = null;
  showMoreOptions = false;
  leasingTerm = 24;
  vehicleCondition = 'new';
  modelsLoaded = true;
  currentBrand;

  public priceMask = createNumberMask({
    prefix: '',
    suffix: '', // This will put the euro sign at the end, with a space.
    thousandsSeparatorSymbol: ' ',
    allowDecimal: true
  });

  constructor(
    private filterService: FilterService,
    private fb: FormBuilder,
    private sdk: ApiService,
    public currencyService: CurrencyService,
  ) {
    this.filterForm = fb.group({
      brand: fb.control(''),
      model: fb.control(0),
      fuel: fb.control(0),
      prepay: fb.control(''),
      user_type: fb.control('person'),
      driveType: fb.control(0),
      body: fb.control(0),
      transmission: fb.control(0),
      term: fb.control(24),
      condition: fb.control(0),
    });
  }

  ngOnInit() {
    if (this.filterService.getFilters().amount) {
      this.amount = this.filterService.getFilters().amount;
      this.filterService.getFilters().amount = null;

      this.filterForm.patchValue({
        prepay: this.amount
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.models && changes.models.firstChange === false) {
      this.modelsLoaded = true;
    }

    if (changes.conditions && changes.conditions.firstChange === false) {
      const selectCondition = this.conditions.filter(value => value.slug === 'new');
      this.filterForm.patchValue({
        condition: selectCondition[0].id
      });
    }
  }

  submit() {
    if (this.filterForm.get('prepay').value) {
      this.filterForm.get('prepay').setValue(this.unMask(this.filterForm.get('prepay').value));
    }

    this.filter.emit(this.filterForm.value);
  }

  toggleMoreOptions() {
    this.showMoreOptions = !this.showMoreOptions;
  }

  setLeasingTerm(term) {
    this.leasingTerm = term;
    this.filterForm.get('term').setValue(term);
  }

  setVehicleCondition(condition) {
    const selectCondition = this.conditions.filter(value => value.slug === condition);
    this.vehicleCondition = condition;
    this.filterForm.get('condition').setValue(selectCondition[0].id);
  }

  unMask(value) {
    if (typeof value === 'string') {
      // @ts-ignore
      return Number(value.replace(/[^\d.-]/g, '', ''));
    }

    return value;
  }
}
