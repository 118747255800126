import {Component, Input, OnInit} from '@angular/core';
import {PopupService} from '../../../../services/popup.service';

@Component({
  selector: 'app-popup-fail',
  templateUrl: './popup-fail.component.html',
  styleUrls: ['./popup-fail.component.scss']
})

export class PopupFailComponent implements OnInit {

  @Input() popupParams;

  constructor(
    public popupService: PopupService,
  ) {}

  ngOnInit() {
  }
}
