import {BasicResponse} from './BasicResponse';
import {Car} from "../../types/car";

/**
 *
 */
export default class GetCarsResponse extends BasicResponse {
  data?: any;
  success: string;

  public relations(): { [p: string]: any } {
    return {
      'data[]': Car
    };
  }
}
