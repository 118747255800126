import {CommandInterface} from '../Interfaces/CommandInterface';
import CommandNotFoundError from '../Errors/CommandNotFoundError';
import {ApiService} from '../api.service';

interface CommandMap {
    [key: string]: CommandInterface;
}

export class CommandBus {
    /**
     * Holds all commands.
     */
    protected commands: CommandMap = {};

    /**
     * Holds all command names.
     */
    protected commandNames: Array<string> = [];

    /**
     * Api instance
     */
    protected sdk: ApiService;

    /**
     * Instantiate Command Bus.
     */
    public constructor($sdk: ApiService) {
        this.sdk = $sdk;
        this.sdk.getConfig('commands').forEach((cmd) => {
            this.addCommand(cmd);
        });
    }

    /**
     * Returns the list of commands.
     */
    public getCommands(): CommandMap {
        return this.commands;
    }

    /**
     * Add a list of commands.
     */
    public addCommands(commands: Array<CommandInterface>): this {
        const self = this;

        commands.forEach((command) => {
            self.addCommand(command);
        });

        return this;
    }

    /**
     * Add a command to the commands list.
     *
     */
    public addCommand(command: CommandInterface): this
    {
        command = this.resolveCommandObject(command);

        if (this.commandNames.indexOf(command.getName()) >= 0) {
            throw Error('Command with this name ' + command.getName() + ' is already exists.');
        } else {
            this.commandNames.push(command.getName());
        }

        this.commands[command.getName()] = command;

        return this;
    }

    /**
     * Execute the command.
     *
     * @throws CommandNotFoundException
     */
    public execute($name, $arguments) {
        const command = this.resolveCommandObject($name);
        if (command && Object.keys(this.commands).indexOf(command.getName()) >= 0) {
            return this.commands[command.getName()].make(this.sdk, $arguments);
        } else {
            throw new CommandNotFoundError($name);
        }
    }

    /**
     * @return object
     */
    private resolveCommandObject($command) {
        if (typeof $command !== 'function') {
            throw new Error('Command class "' + ($command) + '" not found! Please make sure the class exists.');
        } else {
            $command = new $command();
        }

        return $command;
    }
}
