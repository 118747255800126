export class Brand {
  id?: number;
  slug?: string;
  description: string;
  models_count?: number;
  cars_count?: number;
  full_description?: string;
  main_image?: string;
  country?: string;
  logo?: string;
  id_1C?: string;
  visibility?: number;
}
