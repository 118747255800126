import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import { Model } from '../../../../shared/types/model';
import { Image } from '../../../../shared/types/Image';
import { ApiService } from '../../../../shared/SDK/api.service';
import {Car} from '../../../../shared/types/car';
import {OwlCarousel} from 'ngx-owl-carousel';

@Component({
  selector: 'app-catalog-item-slider',
  templateUrl: './catalog-item-slider.component.html',
  styleUrls: ['./catalog-item-slider.component.scss']
})
export class CatalogItemSliderComponent implements OnInit, OnChanges {

  @Input() model: Model;
  @Input() modificationGroupedCars: { [key: string]: Car[]; };
  @ViewChild('owlElement', {static: false}) owlElement: OwlCarousel;

  modificationsLoaded = false;
  car: Car;
  maxCost = 0;
  complectationId = 0;
  images: Image[];
  totalImages = 0;

  constructor(
    private sdk: ApiService
  ) {}

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
      console.log('images', changes);
      this.images = changes.model.currentValue.images;
    }

    if (changes.modificationGroupedCars && Object.keys(this.modificationGroupedCars).length > 0) {
      if (!this.modificationsLoaded) {
        for (const i in changes.modificationGroupedCars.currentValue) {
          if (this.maxCost < changes.modificationGroupedCars.currentValue[i][0].cost) {
            this.maxCost = changes.modificationGroupedCars.currentValue[i][0].cost;
            this.complectationId = changes.modificationGroupedCars.currentValue[i][0].id;
          }
        }
        if (this.complectationId) {
          this.sdk.carEndpoint.getCar(this.complectationId).then((data) => {
            this.car = data;
            if (this.car.model && this.car.model.images && this.car.model.images.length) {
              this.totalImages = this.car.model.images.length;
              this.car.model.images = data.model.images.filter((value) => !value.url.match(/\/preview\//g));
            }
          });
        }
      }
      console.log(this.maxCost, this.complectationId);
      this.modificationsLoaded = true;
    }
  }

  sliderMoveLeft() {
    this.owlElement.previous([200]);
  }

  sliderMoveRight() {
    this.owlElement.next([200]);
  }
}
// http://localhost:4200/catalog/acura/integra-hetchbek-30
// http://localhost:4200/catalog/abarth/124-spider-348-2
