import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ApiService } from '../../shared/SDK/api.service';
import {Title} from '@angular/platform-browser';
import GetCarsCommand from "../../shared/SDK/Commands/GetCarsCommand";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  public loginStep: string;

  constructor(
    private authService: AuthService,
    public apiService: ApiService,
    private title: Title,
  ) {
  }

  ngOnInit() {
//    this.loginStep = this.authService.loginStep["source"]["_value"];
//     this.apiService.triggerCommand(GetCarsCommand, {'search' : 'some'})
  }

  public changeLanguage(lang: string) {
  }
}
