import {Component, OnInit, ViewChild} from '@angular/core';
import {OwlCarousel} from 'ngx-owl-carousel';
import {PopupService} from '../../../../services/popup.service';

@Component({
  selector: 'app-top-offer',
  templateUrl: './top-offer.component.html',
  styleUrls: ['./top-offer.component.scss']
})

export class TopOfferComponent implements OnInit {

  public images: any[] = [];

  @ViewChild('owlElement', {static: false}) owlElement: OwlCarousel;

  constructor(
    public popupService: PopupService,
  ) {}

  ngOnInit() {
    this.images.push({path: '/assets/images/temp1.png'});
    this.images.push({path: '/assets/images/temp1.png'});
    console.log(this.images)
  }

  sliderMoveLeft() {
    this.owlElement.previous([200]);
  }

  sliderMoveRight() {
    this.owlElement.next([200]);
  }
}
