import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageService} from '../../../../services/message.service';

@Component({
  selector: 'app-individual-form',
  templateUrl: './individual-form.component.html',
  styleUrls: ['./individual-form.component.scss']
})

export class IndividualFormComponent implements OnInit {

  public individualForm: FormGroup;
  public submitted = false;


  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit() {
    this.individualForm = this.fb.group({
      surname: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      message: ['', [Validators.required]],
    });
  }

  sendMessage() {
    this.submitted = true;
    if (this.individualForm.status === 'VALID') {
      this.submitted = false;
      this.messageService.sendMessage(this.individualForm.controls, 'individual');
      this.individualForm.reset();
    }
  }

  get f() {
    return this.individualForm.controls;
  }

  get surname() {
    return this.individualForm.get('surname');
  }

  get phone() {
    return this.individualForm.get('phone');
  }

  get message() {
    return this.individualForm.get('message');
  }
}
