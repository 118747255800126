import {BasicResponse} from './BasicResponse';
import {Brand} from "../../types/brand";

/**
 *
 */
export default class GetBrandsResponse extends BasicResponse {
  data?: any;
  success: string;

  public relations(): { [p: string]: any } {
    return {
      'data[]': Brand
    };
  }
}
