import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../shared/SDK/api.service';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})

export class LoginLayoutComponent implements OnInit {

  public lang: string;

  constructor(public api: ApiService) {
    console.log('LoginLayoutComponent');
  }

  ngOnInit() {
    console.log(this.api.carEndpoint)
    this.api.carEndpoint.search({prepay: 1000}).then(value => {
      console.log(value);
    });
  }
}
