import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import {CurrencyService} from "../services/currency.service";

/*
 * Format number with currency symbol.
 * Usage:
 *   number | price:(true/false)
 * Example:
 *   {{ 1234.23 | currencySymbol }}
 *   formats to: 1 234.23 €
 *
 *   {{ 1234.23 | currencySymbol:true }}
 *   formats to: 1 234.23 EUR
*/
@Pipe({name: 'price'})
export class PricePipe implements PipeTransform {
  constructor(public currencyService: CurrencyService) {
  }

  transform(data: number, showCode: boolean = false): string {
    let resultData = data.toLocaleString( [ 'jav', 'en' ]).replace(/,/g," ",);
    let key = this.currencyService.currency.toUpperCase();

    return resultData + ' ' + (showCode ? key : getCurrencySymbol(key, 'narrow'));
  }
}
