import {RelationsInterface} from "../SDK/Interfaces/RelationsInterface";
import {Brand} from "./brand";
import {Car} from "./car";
import {Image} from "./Image";

export class Model implements RelationsInterface {
  id?: number;
  brand_id: number;
  slug: string;
  description: string;
  full_description: string;
  visibility: number;

  brand?: Brand;
  first_car?: Car; // первая подходящая комплекстация модели по условиям для модели в списке
  cars_count?: number; // сколько еще комплекстаций у модели есть
  images: Image[];

  public relations(): { [p: string]: any } {
    return {
      brand: Brand,
      first_car: Car,
      'cars[]': Car,
      'images[]': Image
    };
  }
}
