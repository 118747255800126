import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../services/scroll.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss']
})

export class EquipmentComponent implements OnInit {

  constructor(
    public scrollServices: ScrollService,
  ) {}

  ngOnInit() {
  }
}
