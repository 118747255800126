import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../services/scroll.service';

@Component({
  selector: 'app-dealers',
  templateUrl: './dealers.component.html',
  styleUrls: ['./dealers.component.scss']
})

export class DealersComponent implements OnInit {

  constructor(
    public scrollServices: ScrollService,
  ) {}

  ngOnInit() {
  }
}
