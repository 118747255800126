import { AxiosResponse } from 'axios'
import get from 'lodash/get'
import BasicApiError from './BasicApiError'

export default class ExpandedApiError extends BasicApiError {
    constructor(serverResponse: AxiosResponse, errorsSummary: object) {
        super(serverResponse, errorsSummary);
        Object.setPrototypeOf(this, new.target.prototype);
        this.name = 'ExpandedApiError';
        this.error = errorsSummary;
    }
}
