import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../shared/SDK/api.service';
import {Brand} from '../../shared/types/brand';
import {ScrollService} from '../../services/scroll.service';
import {NavigationEnd, Router} from '@angular/router';
import {TranslateService, TranslationChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})

export class IndexComponent implements OnInit {

  public brands: Brand[];

  constructor(
    public api: ApiService,
    public scrollServices: ScrollService,
    public translateService: TranslateService,
  ) {}

  async transByLang (key, language) {
    const prevLang = this.translateService.currentLang;

    await this.translateService.use(language).toPromise();

    return await this.translateService.get(key).toPromise()
      .then((result) => {
        this.translateService.use(prevLang);  // restore prev lang
        return result;
      });
  }

  async ngOnInit() {
    { // test translation, examples
      // by lang
      let translated = await this.transByLang('hello', 'ru');
      console.error('translated', translated, 'ru');
      translated = await this.transByLang('hello', 'uk');
      console.error('translated', translated, 'uk');
      translated = await this.transByLang('hello', 'en');
      console.error('translated', translated, 'en');

      // current language
      translated = await this.translateService.get('hello').toPromise();
      console.error('translated', translated, this.translateService.currentLang);
    }

    let limit = 12;
    const screenWidth = window.innerWidth;
    if (screenWidth < 767) {
      limit = 6;
    }
    // const params = {offset: 0, limit};
    // this.api.listEndpoint
    //   .getBrandsOnlyMain(params)
    //   .then((data: Brand[]) => {
    //     this.brands = data;
    //   });
    this.api.listEndpoint
      .getBrands({})
      .then((data: Brand[]) => {
        this.brands = data;
      });
  }
}
