import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../../../services/scroll.service';
import {ActivatedRoute} from '@angular/router';
import {Image} from '../../../../shared/types/Image';
import {ApiService} from '../../../../shared/SDK/api.service';
import {Car} from '../../../../shared/types/car';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import emailMask from 'text-mask-addons/dist/emailMask';

@Component({
  selector: 'app-catalog-item-reserve',
  templateUrl: './catalog-item-reserve.component.html',
  styleUrls: ['./catalog-item-reserve.component.scss']
})

export class CatalogItemReserveComponent implements OnInit {

  id: string;
  user_type: string;
  amount: number;
  term: number;
  car: Car;
  public reserveForm: FormGroup;
  public emailMask = emailMask;
  public formDisabled = true;

  constructor(
    public scrollServices: ScrollService,
    private sdk: ApiService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.reserveForm = this.fb.group({
      name: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      coment: [''],
      isAgree: ['', [Validators.required]],
    });

    this.id = this.route.snapshot.paramMap.get('complectSlug');
    this.user_type = this.route.snapshot.queryParamMap.get('user_type');
    this.amount = Number(this.route.snapshot.queryParamMap.get('amount'));
    this.term = Number(this.route.snapshot.queryParamMap.get('term'));
    this.sdk.carEndpoint.getCar(this.id).then((data) => {
      this.car = data;
    });
  }

  checkFormValidation() {
    console.log(this.reserveForm);
    (this.reserveForm.status === 'VALID' && this.isAgree.value) ? this.formDisabled = false : this.formDisabled = true;
  }

  sendMessage() {
    alert('Message have been sent!');
  }

  get isAgree() {
    return this.reserveForm.get('isAgree');
  }
}
