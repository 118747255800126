import {Component, OnInit} from '@angular/core';
import {FormGroup, FormControl} from '@angular/forms';
import {ApiService} from '../../shared/SDK/api.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import BasicApiError from '../../shared/SDK/Errors/BasicApiError';

@Component({
  selector: 'app-check-api',
  templateUrl: './check-api.component.html',
  styleUrls: ['./check-api.component.scss']
})

export class CheckApiComponent implements OnInit {
  private checkApiForm: FormGroup;

  public endpoints = [];
  public endpoint: string = null;

  public methods = [];
  public method: string = null;

  public services = [];
  public service: string = null;

  public serviceMethods = [];
  public serviceMethod: string = null;

  public params = '';
  public methodInfo = '';
  public showExamples = false;
  public neededParamsString = '';

  public isSimpleResult = false;

  public response = '';

  constructor(
    private router: Router, private apiService: ApiService, private authService: AuthService
  ) {
  }
  ngOnInit() {
    this.endpoints = Object.keys(this.apiService.getConfig('endpoints'));
    this.endpoint = localStorage.getItem('selectedEndpoint') || '';
    if (this.endpoint && this.endpoint.length > 0) {
      this.selectedEndpoint(true);
    }

    this.method = localStorage.getItem('selectedMethod') || '';
    if (this.method && this.method.length > 0) {
      this.selectedMethod();
    }

    const services = Object.keys(this);
    this.services = services.filter((value, index, array) => value.indexOf('Service') !== -1);
    this.service = localStorage.getItem('selectedService') || '';
    if (this.service && this.service.length > 0) {
      this.selectedService(true);
    }

    this.serviceMethod = localStorage.getItem('selectedServiceMethod') || '';
    if (this.serviceMethod && this.serviceMethod.length > 0) {
      this.selectedServiceMethod();
    }
  }

  getAllFuncs(obj, bAndObjects = false) {
    let props = [];
    const skip = ['constructor'];
    const prototype = Object.getPrototypeOf(obj);

    do {
      props = props.concat(Object.getOwnPropertyNames(prototype));
    } while (obj === Object.getPrototypeOf(obj));

    return props.sort().filter((e, i, arr) => {
      if (e !== arr[i + 1] && typeof obj[e] === 'function' && (skip.indexOf(e) === -1)) {
        return true;
      }
      if (bAndObjects && e !== arr[i + 1] && typeof obj[e] === 'object' && (skip.indexOf(e) === -1)) {
        return true;
      }
    });
  }

  selectedEndpoint(init: boolean = false) {
    if (this.endpoint && this.endpoint.length > 0) {
      localStorage.setItem('selectedEndpoint', this.endpoint);
    }
    const endpoint = this.apiService[this.endpoint];

    if (endpoint) {
      this.methods = this.getAllFuncs(endpoint, true);
    } else {
      this.method = null;
      localStorage.removeItem('selectedMethod');
      this.methods = [];
      localStorage.removeItem('selectedEndpoint');
    }
    if (!init) {
      this.method = null;
      localStorage.removeItem('selectedMethod');
    }
  }

  selectedService(init: boolean = false) {
    if (this.service && this.service.length > 0) {
      localStorage.setItem('selectedService', this.service);
    }
    const service = this[this.service];

    if (service) {
      this.serviceMethods = this.getAllFuncs(service, true);
    } else {
      this.serviceMethod = null;
      localStorage.removeItem('selectedServiceMethod');
      this.serviceMethods = [];
      localStorage.removeItem('selectedService');
    }
    if (!init) {
      this.serviceMethod = null;
      localStorage.removeItem('selectedServiceMethod');
    }
  }

  selectedMethod() {
    if (this.method && this.method.length > 0) {
      localStorage.setItem('selectedMethod', this.method);
    }
    const endpoint = this.apiService[this.endpoint];

    if (endpoint) {
      const method = endpoint[this.method];

      if (method === undefined) {
        return;
      }

      this.methodInfo = method.toString();
      this.neededParamsString = this.getParams(method);
    }
  }

  selectedServiceMethod() {
    if (this.serviceMethod && this.serviceMethod.length > 0) {
      localStorage.setItem('selectedServiceMethod', this.serviceMethod);
    }
    const service = this[this.service];

    if (service) {
      const serviceMethod = service[this.serviceMethod];

      if (serviceMethod === undefined) {
        return;
      }

      this.methodInfo = typeof serviceMethod === 'function' ? serviceMethod.toString() : serviceMethod;
      this.neededParamsString = typeof serviceMethod === 'function' ? this.getParams(serviceMethod) : '- нет';
    }
  }

  getParams(func) {
    const str = func.toString();
    const len = str.indexOf('(');
    return str.substr(len + 1, str.indexOf(')') - len - 1).replace(/ /g, '').split(',');
  }

  send() {
    this.response = '...';
    try {
      const parsedParams = this.params.length > 0 ? JSON.parse(this.params) : [];

      const endpoint = this.apiService[this.endpoint];

      if (endpoint) {
        const method = endpoint[this.method];

        if (method === undefined) {
          return;
        }

        if (typeof method === 'object') {
          this.response = method;
          return;
        }

        if (this.isSimpleResult) {
          this.response = method.apply(endpoint, parsedParams);
        } else {
          method.apply(endpoint, parsedParams)
            .then(res => {
              console.debug('[CheckApi] Response', res);
              this.response = res;
            })
            .catch((error: BasicApiError) => {
              console.error('[CheckApi] GOT BasicError', error.error);
              this.response = JSON.stringify(error.error);
            })
            .catch(error => {
              console.error('[CheckApi] GOT ERROR', error);
              this.response = JSON.stringify(error);
            });
        }
      }

      const service = this[this.service];

      if (service) {
        const serviceMethod = service[this.serviceMethod];

        if (serviceMethod === undefined) {
          return;
        }

        if (typeof serviceMethod === 'object') {
          this.response = serviceMethod;
          return;
        }

        if (this.isSimpleResult) {
          this.response = serviceMethod.apply(service, parsedParams);
        } else {
          serviceMethod.apply(service, parsedParams)
            .then(res => this.response = res)
            .catch(error => this.response = error);
        }
      }
    } catch (e) {
      this.response = e.toString();
      console.error(e);
    }
  }
}
