import {Component, OnInit} from '@angular/core';
import {ScrollService} from '../../../../services/scroll.service';
import { ApiService } from '../../../../shared/SDK/api.service';
import { ActivatedRoute } from '@angular/router';
import { Car } from '../../../../shared/types/car';
import { Image } from '../../../../shared/types/Image';

@Component({
  selector: 'app-catalog-item-complectation',
  templateUrl: './catalog-item-complectation.component.html',
  styleUrls: ['./catalog-item-complectation.component.scss']
})

export class CatalogItemComplectationComponent implements OnInit {

  id: string;
  modelSlug: string;
  brandSlug: string;
  urlBrand = '';
  urlModel = '';
  car: Car;
  imgUrl: string;

  constructor(
    public scrollServices: ScrollService,
    private sdk: ApiService,
    private route: ActivatedRoute,
  ) {
    this.id = this.route.snapshot.paramMap.get('complectSlug');
    this.modelSlug = this.route.snapshot.paramMap.get('modelSlug');
    this.brandSlug = this.route.snapshot.paramMap.get('brandSlug');
    this.urlBrand = `/catalog/${this.brandSlug}`;
    this.urlModel = `/catalog/${this.brandSlug}/${this.modelSlug}`;
    this.sdk.carEndpoint.getCar(this.id).then((data) => {
      this.car = data;
      if (this.car.model && this.car.model.images && this.car.model.images.length) {
        this.imgUrl = sdk.getHost('/api') + '/' + this.car.model.images.filter((value: Image) => {
          return value.type === 'main';
        })[0].url;
      }
    });
  }

  ngOnInit() {}
}
