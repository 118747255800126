import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Feedback} from '../shared/types/feedback';
import {PopupService} from './popup.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class MessageService {

  constructor(
    private http: HttpClient,
    private popupService: PopupService,
    private translateService: TranslateService,
  ) {}

  sendMessage(form, mode) {
    // const body = new FormData();
    let name = '';
    let phone = '';
    let email = '';
    let message = '';
    let emailToValue;
    let emailCcValue = '';
    let emailBccValue = '';
    let subjectValue = '';
    switch(mode) {
      case 'individual':
        emailToValue = 'm.denysemko@zet.finance';
        emailCcValue = 'm.gritsenko@zet.finance';
        // emailToValue = 'mastrele@gmail.com';
        // emailCcValue = 'mastrele@gmail.com';
        emailBccValue = 'mastrele@gmail.com';
        subjectValue = 'Индивидуальный запрос';
        break;
      case 'consulting':
        emailToValue = 'm.denysemko@zet.finance';
        emailCcValue = 'm.gritsenko@zet.finance';
        emailBccValue = 'mastrele@gmail.com';
        subjectValue = 'Заказать консультацию';
        break;
      case 'contactUs':
        emailToValue = 'm.denysemko@zet.finance';
        emailCcValue = 'm.gritsenko@zet.finance';
        emailBccValue = 'mastrele@gmail.com';
        subjectValue = 'Свяжитесь с нами';
        break;
    }

    if (form.surname && form.surname.value) {
      name = '<b>Имя:</b><br>' + form.surname.value + '<br><br>';
    }
    if (form.phone && form.phone.value) {
      phone = '<b>Телефон:</b><br>' + form.phone.value + '<br><br>';
    }
    if (form.email && form.email.value) {
      email = '<b>Email:</b><br>' + form.email.value + '<br><br>';
    }
    if (form.message && form.message.value) {
      message = '<b>Сообщение:</b><br>' + form.message.value.replace(/\n/g, '<br>');
    }

    message = name + phone + email + message;

    const headers = new HttpHeaders({Accept: 'application/json', 'Content-Type': 'application/json'});

    const credentials = {
      email: 'clynch@example.net',
      password: 'gHfJPwWtfMYxkTp34pWY'
    };

    this.http.post('https://dev.zet.finance/api/login', credentials, {headers})
      .subscribe((data: Feedback) => {
        console.log(data);
        if (data.access_token) {
          const formData = {
            to: emailToValue,
            cc: emailCcValue,
            bcc: emailBccValue,
            subject: subjectValue,
            name: 'zet.finance',
            email: 'info@zet.finance',
            bodyMessage: message,
            token: data.access_token,
            token_type: 'bearer'
          };

          return this.http.post('https://dev.zet.finance/api/mail', formData, {headers})
            .subscribe((data: any) => {
              if (!data.errors) {
                const params = {text: this.translateService.instant('forms.request_sent'),};
                this.popupService.showPopup('success', params);
              } else {
                this.popupService.showPopup('fail', data.errors);
              }
              console.log(data);
            });
        }
      });
  }

  handleError(error: any) {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    console.error(errMsg); // log to console instead
    // return Observable.throw(errMsg);
  }
}
