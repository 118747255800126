import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-docs-examples',
  templateUrl: './docs-examples.component.html',
  styleUrls: ['./docs-examples.component.scss']
})

export class DocsExamplesComponent implements OnInit {

  constructor() {}

  ngOnInit() {
  }
}
