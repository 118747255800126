import {ApiService} from "../api.service";
import {EndpointInterface} from "../Interfaces/EndpointInterface";

export abstract class Endpoint implements EndpointInterface
{
    protected sdk: ApiService;

    public constructor(sdk: ApiService) {
        this.sdk = sdk;
    }
}
