import {ErrorHandler, Injectable} from '@angular/core';
import {CommandBus} from './Commands/CommandBus';
import {EndpointBus} from './endpoints/EndpointBus';
import {environment} from '../../../environments/environment';
import {isArray, isNull, log} from 'util';
import Axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';
import qs from 'qs';
import {DELETE, GET} from './constants';
import {ErrorClass} from './Interfaces/Errors/ErrorClass';
import ApiSDKError from './Errors/ApiSDKError';
import NoResponseError from './Errors/NoResponseError';
import MisconfigurationError from './Errors/MisconfigurationError';
import ApiError from './Errors/ApiError';
import ExpandedApiError from './Errors/ExpandedApiError';
import BasicApiError from './Errors/BasicApiError';
import CarEndpoint from './endpoints/CarEndpoint';
import {AuthService} from '../../services/auth.service';
import {ModelMapper} from './modelMapper';
import * as moment from 'moment';
import ListEndpoint from './endpoints/ListEndpoint';
import {CurrencyService} from "../../services/currency.service";

export interface Params {
  [key: string]: any;
}

export interface GetOptions {
  url: string;
  params?: Params;
}

export interface ErrorResponse {
  id: string;
  code: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  version = '0.0.1';

  protected config: any;

  protected client: AxiosInstance;
  protected host: string = '';

  private errorHandler: ErrorHandler;

  protected commandBus: CommandBus;
  protected endpointBus: EndpointBus;

  protected timeOut = 60 * 1000;

  protected connectionTimeOut = 10;

  public buffer: any = {};

  /**
   * Endpoints
   */
  public carEndpoint: CarEndpoint;
  public listEndpoint: ListEndpoint;

  constructor(errorHandler: ErrorHandler, public authService: AuthService, public currencyService: CurrencyService) {
    log('ApiService init');
    this.errorHandler = errorHandler;
    this.config = environment.api;
    let apiUrl = localStorage.getItem('apiUrl');
    if (!apiUrl) {
      apiUrl = environment.api.host;
    }
    this.host = apiUrl;
    this.client = Axios.create({
      baseURL: apiUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, {arrayFormat: 'brackets'});
      },
      timeout: this.timeOut,
    });
    this.commandBus = new CommandBus(this);
    this.endpointBus = new EndpointBus(this);

    this.checkAuthenticatedAccess();
  }

  public static fetchCastingFields(object: object): any {
    let dates = [];
    let times = [];
    let dateTimes = [];

    if (typeof object === 'object') {
      if (typeof object['dateCastingFields'] === 'function') {
        dates = object['dateCastingFields']();
      }
      if (typeof object['timeCastingFields'] === 'function') {
        times = object['timeCastingFields']();
      }
      if (typeof object['dateTimeCastingFields'] === 'function') {
        dateTimes = object['dateTimeCastingFields']();
      }
    }

    return {dates, times, dateTimes};
  }

  public static toAPIObject(object: object): any {
    const keys = Object.getOwnPropertyNames(object);
    const result = {};
    const {dates, times, dateTimes} = this.fetchCastingFields(object);

    for (const key of keys) {
      if (object[key] == null || object[key] === undefined) {
        // Do nothing
      } else if (isArray(object[key])) {
        object[key].forEach((value) => {
          value = ApiService.toAPIObject(value);
        });
        result[key] = object[key];
      } else if (object[key] instanceof Object && typeof object[key + 'ToAPIFormat'] === 'function') {
        result[key] = object[key + 'ToAPIFormat']();
      } else if (object[key] instanceof Object && dates.indexOf(key) !== -1 && object[key].constructor.name === 'Moment') {
        result[key] = object[key].format('DD-MM-YYYY');
      } else if (object[key] instanceof Object && times.indexOf(key) !== -1 && object[key].constructor.name === 'Moment') {
        result[key] = object[key]['format']('HH:mm');
      } else if (object[key] instanceof Object && dateTimes.indexOf(key) !== -1 && object[key].constructor.name === 'Moment') {
        result[key] = object[key]['format']('DD-MM-YYYY HH:mm');
      } else if (object[key] instanceof Object && typeof object[key].ref !== undefined) {
        result[key] = object[key].ref;
      } else {
        result[key] = object[key];
      }

      if (object[key] && object[key].constructor.name === 'Moment') {
        console.log(object[key].format('DD-MM-YYYY'));
      }
    }

    return result;
  }

  public static fromAPIObject(object: object, mustBeType = null): any {
    const keys = Object.getOwnPropertyNames(object);
    console.log(keys);
    const result = {};
    const typeObject = typeof mustBeType === 'function' ? new mustBeType() : null;
    const {dates, times, dateTimes} = this.fetchCastingFields(typeObject);

    for (const key of keys) {
      if (isArray(object[key])) {
        // TODO: обрабатывать вложенные уровни, придумать как вытягивать mustBeType, рефакторинг
        // object[key].forEach((value) => {
        //   if (!isNull(typeObject) && typeof typeObject[key] !== 'undefined') {
        //     value = ApiService.fromAPIObject(value, typeObject[key].constructor.name);
        //   }
        //   // value = ApiService.fromAPIObject(value);
        // });
        result[key] = object[key];
      } else if (typeObject instanceof Object && typeof typeObject[key + 'FromAPIFormat'] === 'function') {
        result[key] = typeObject[key + 'FromAPIFormat'](object[key]);
      } else if (dates.indexOf(key) !== -1) {
        result[key] = moment(object[key], 'DD-MM-YYYY');
      } else if (times.indexOf(key) !== -1) {
        result[key] = moment(object[key], 'HH:mm');
      } else if (dateTimes.indexOf(key) !== -1) {
        result[key] = moment(object[key], 'DD-MM-YYYY HH:mm');
      } else if (object[key] instanceof Object && typeof object[key].ref !== undefined) {
        // TODO: load object by ref and by type ??? пока ненадо т.к АПИ передает сразу все
        result[key] = object[key];  // если прилетел объект от АПИ то его ставим как есть
      } else {
        result[key] = object[key];
      }
    }

    if (isNull(mustBeType)) {
      return result;
    }

    return ModelMapper.mapper(result, mustBeType);
  }

  public setApiUrl($url: string) {
    this.client.defaults.baseURL = $url;
    localStorage.setItem('apiUrl', $url)
    return this.client.defaults.baseURL;
  }

  public getConfig($key: string = null, $default = null) {
    if (isNull($key)) {
      return this.config;
    }

    return this.config[$key] || $default;
  }

  public setConfig($config) {
    this.config = $config;

    return this;
  }

  public getClient() {
    return this.client;
  }

  public getCommandBus() {
    return this.commandBus;
  }

  public triggerCommand($name, $arguments = {}): Promise<any> {
    return this.getCommandBus().execute($name, $arguments);
  }

  public async apiResponse<T>(
    method: string, route: string, params: any = {}, itemType: any = null, customConfigs: {} = {}
  ): Promise<T> {
    try {
      let res;
      let configs;
      const reqFunc = this.client[method];

      this.apiOrderParams(params);

      if (method === GET || method === DELETE) {
        configs = this.apiOrderConfigs(customConfigs, params);
        res = await reqFunc(route, configs);
      } else {
        configs = this.apiOrderConfigs(customConfigs);
        res = await reqFunc(route, params, configs);
      }

      if (res.data.error === undefined && (res.data.success === 'true' || res.data.success || res.headers['content-type'] === 'application/pdf')) {
        return itemType === null ? (res.data.data || res.data) : (res.data || res); // обратная совместимость
      }

      return (Promise.reject(
        this.okResponse(res)
      ));
    } catch (error) {
      return (Promise.reject(
        this.noResponse(error)
      ));
    }
  }

  public okResponse(res: any) {
    return this.apiErrorHandler(this.processApiError(res));
  }

  public noResponse(error) {
    return this.sdkErrorHandler(this.processError(error));
  }

  /**
   * HTTP error code returned by api is not indicative of its response shape. This function attempts to figure out the
   * information provided from api and use whatever is available.
   */
  private classifyError(response: AxiosResponse): ErrorClass {
    const {error: errorSummary} = response.data;

    if (typeof errorSummary === 'object') {
      if (typeof errorSummary.text === 'string') {
        return ErrorClass.FULL;
      }
      return ErrorClass.BASIC;
    }
    return ErrorClass.LIMITED;
  }

  private processError(error: AxiosError): ApiSDKError {
    if (error.response) {
      // Error from api outside HTTP 2xx codes
      return this.processApiError(error.response);
    } else if (error.request) {
      // No response received from api
      return new NoResponseError();
    } else {
      // Incorrect request setup
      return new MisconfigurationError(error.message);
    }
  }

  private processApiError(response: AxiosResponse): ApiError {
    const {error: errorSummary} = response.data;
    const errorClass = this.classifyError(response);

    if (errorClass === ErrorClass.FULL) {
      return new ExpandedApiError(response, errorSummary);
    } else if (errorClass === ErrorClass.BASIC) {
      return new BasicApiError(response, errorSummary);
    } else {
      return new ApiError(response);
    }
  }

  /**
   * Ошибки АПИ, например валидации.
   */
  private apiErrorHandler(error: ApiError) {
    // например из другого сервиса вызвать показ уведомления о ошибке
    return error;
  }

  /**
   * Ошибки СДК, связи.
   */
  private sdkErrorHandler(error: ApiSDKError) {
    // например из другого сервиса вызвать показ уведомления о ошибке
    if (error.constructor.name === 'NoResponseError') { // нет инета
      //
    } else { // остальные ошибки
      //
    }
    return error;
  }

  private apiOrderParams($params) {

    /*if (this.authService.isAuthenticated()) {
      $params.apiKey = this.authService.getAuthUserRaw().apiKey;

      const businessAccount = this.authService.getNullableBusinessAccount();
      if (businessAccount) {
        $params.accountRef = businessAccount.ref;
      }
    }
    $params.app = 'cabinet';*/
  }

  private apiOrderConfigs(customConfigs, params = null) {
    let configs: any = {headers: {}, params: {}};
    // TODO: jwn token

    if (customConfigs) {
      configs = this.mergeDeep(configs, customConfigs);
    }

    if (params) {
      configs.params = params;
    }

    configs.headers['X-Currency'] = this.currencyService.currency;

    return configs;
  }

  public checkAuthenticatedAccess() {
  }

  /**
   * Simple is object check.
   */
  public isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
  }

  /**
   * Deep merge two objects.
   */
  public mergeDeep(target, source) {
    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, {[key]: {}});
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, {[key]: source[key]});
        }
      }
    }

    return target;
  }

  public getHost(without: string = '') {
    return this.host.replace(without, '');
  }
}
