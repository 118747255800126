import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {AuthService} from '../../services/auth.service';
import {ApiService} from '../../shared/SDK/api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Brand} from '../../shared/types/brand';
import {IndividualPanelComponent} from '../../shared/components/individual-panel/individual-panel.component';
import {CurrencyService} from '../../services/currency.service';
import {PopupService} from '../../services/popup.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent implements OnInit {

  @Input() brands: Brand[];

  public lang: string;
  public userMode: string;
  public currentPage: string;
  public user: Account;
  public contractDataForm: FormGroup;
  public isActivatedbusinessAccount = false;
  public titleContractDataForm: string;
  public textContractDataForm: string;
  public requestBusinessAccountPopupShowed = false;
  public businessAccount;
  public headerScrollable = false;
  public mobileMenu = false;
  public favoritesOpened = false;
  public ref: string;
  public brandSlug: string;
  public offerPage: boolean;
  public catalogPage: boolean;
  public individualSelection = false;
  public langsShow = false;

  @Input() offerItem: string;
  @Input() textPage: boolean;
  @Input() id: string;
  @ViewChild('individualPanelComponent', {static: false}) individualPanelComponent: IndividualPanelComponent;

  scrollValue: number;
  @Output() scrollChange = new EventEmitter<number>();
  @Input()
  get scrolled(): number {
    return this.scrollValue;
  }
  set scrolled(val: number) {
    if (val) {
      this.headerScrollable = true;
    } else {
      this.headerScrollable = false;
    }
    this.scrollValue = val;
    this.scrollChange.emit(this.scrollValue);
  }

  get currencies() {
    return CurrencyService.availableCurrencies;
  }

  constructor(
    public authService: AuthService,
    public apiService: ApiService,
    public currencyService: CurrencyService,
    public router: Router,
    private title: Title,
    private route: ActivatedRoute,
    public popupService: PopupService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.lang = this.translateService.currentLang;
    const url = this.router.url;
    this.ref = this.route.snapshot.params['ref'];
    this.brandSlug = this.route.snapshot.params['brandSlug'];
    if (url.match(/offers/) && this.ref) {
      this.offerPage = true;
    }
    if (url.match(/catalog/) && this.brandSlug) {
      this.catalogPage = true;
    }
  }

  logout() {
  }

  showMobileMenu() {
    this.mobileMenu = !this.mobileMenu;
  }

  showFavorites() {
    this.favoritesOpened = !this.favoritesOpened;
  }

  showIndividualSelection() {
    this.individualSelection = true;
  }

  hideIndividualSelection() {
    this.individualSelection = false;
  }

  setLang(lang: string) {
    this.lang = lang;
    this.translateService.use(lang);
    setTimeout(() => {
      this.popupService.closeDropdown();
    }, 10);
  }

  setCurrency(currency) {
    this.currencyService.currency = currency;
    setTimeout(() => {
      this.popupService.closeDropdown();
    }, 10);
  }
}
