import {Endpoint} from './Endpoint';
import {ApiService} from '../api.service';
import {AbstractControl, FormGroup} from '@angular/forms';
import SomeCheckFormDataCommand from '../Commands/SomeCheckFormDataCommand';
import SomeCheckFormDataResponse from '../responses/SomeCheckFormDataResponse';
import ApiError from '../Errors/ApiError';
import {Car} from '../../types/car';
import GetCarsCommand from '../Commands/GetCarsCommand';

export default class CarEndpoint extends Endpoint {
  /**
   * delay function for someCheckValidator
   */
  static someCheckValidatorDelay;

  /**
   * custom api someCheckValidator
   */
  static someCheckValidator(apiService: ApiService, form: FormGroup, isAsync = true) {
    return (control: AbstractControl) => {
      return new Promise((resolve, reject) => {
        clearTimeout(this.someCheckValidatorDelay);
        this.someCheckValidatorDelay = setTimeout(() => {
          apiService.triggerCommand(SomeCheckFormDataCommand, form.getRawValue())
            .then((data: SomeCheckFormDataResponse) => {
              // при успехе удаляем ошибки (если они раньше были) от этого валидатора
              control.setErrors(ApiError.clearErrors(control.errors));
              return resolve(control.errors);
            })
            .catch((apiError: ApiError) => {
              /**
               * АПИ оветило что какое-то поле не верное
               * преобразуем ответ АПИ к формату ошибок валидации формы нашего валидатора
               * указуем в контроле текущего поля что есть ошибки
               */
              control.setErrors(apiError.map2ValidationErrors(control.errors));
              // передаем ошибку выше по уровню
              return reject(control.errors);
            });
        }, isAsync ? 500 : 0);
      });
    };
  }

  /**
   * Получить список автомобилей
   * @param searchString FilterCar
   * @return Promise<Car[]>
   */
  public search(searchString: FilterCar): Promise<Car[]> {
    return this.sdk.triggerCommand(GetCarsCommand, searchString);
  }

  /**
   * Получить комплекацию авто по ID
   * @param id number
   */
  public getCar(id: number | string): Promise<Car> {
    return this.sdk.triggerCommand(GetCarsCommand, {id}).then(data => data[0]);
  }
}

class FilterCar {
  prepay?: number;
  limit?: number;
  page?: number;
  term?: number;
  user_type?: string;  // entity | person
  model?: string|number; // id | slug
  brand?: number; // id
  fuel?: number; // id
  condition?: number; // id
  driveType?: number; // id
  body?: number; // id
  transmission?: number; // id
  cost?: string; // 'desc' | 'asc'
}
