import {ApiService} from "./api.service";
import * as moment from "moment";

export class ModelMapper<T> {
  _propertyMapping: any;
  _target: any;

  constructor(type: { new(): T; }) {
    this._target = new type();
    this._propertyMapping = this._target.constructor._propertyMap;
  }

  static mapper(data: any, itemType: any = null): any {
    if (itemType !== null) {
      return new ModelMapper(itemType).map(data);
    } else {
      return data;
    }
  }

  map(source) {
    if (!source) {
      return this._target;
    }
    Object.keys(this._target).forEach((key) => {
      const mappedKey = this._propertyMapping === undefined || this._propertyMapping[key] === undefined ?
        undefined
        :
        this._propertyMapping[key]
      ;
      if (mappedKey) {
        this._target[key] = source[mappedKey];
      } else {
        this._target[key] = source[key];
      }
    });
    Object.keys(source).forEach((key) => {
      const targetKeys = Object.keys(this._target);
      if (targetKeys.indexOf(key) === -1) {
        this._target[key] = source[key];
      }
    });
    if (typeof this._target['relations'] === 'function') {
      this._target = this.mapRelatives(this._target);
    }
    return this._target;
  }

  mapRelatives(obj) {
    const relations = obj.relations();

    const keys = Object.getOwnPropertyNames(obj);
    const {dates, times, dateTimes} = ApiService.fetchCastingFields(this._target);

    for (const key of keys) {
      const value = obj[key];
      let className = '';

      if (relations[key + '[]'] !== undefined) {
        const keyArr = key + '[]';
        className = relations[keyArr];
        const classObjs = [];
        for (const val of value) {
          // classObjs.push(BasicResponse.getInstance(className, val));
          classObjs.push(ModelMapper.mapper(val, className));
        }
        obj[key] = classObjs;
        continue;
      }
      if (relations[key] === undefined) { // field without relation
        if (key === '_raw' || key === '_visible') {
          continue;
        }

        if (typeof this._target[key + 'FromAPIFormat'] === 'function') {
          obj[key] = this._target[key + 'FromAPIFormat'](value);
        } else if (dates.indexOf(key) !== -1) {
          obj[key] = moment(value, 'DD-MM-YYYY');
        } else if (times.indexOf(key) !== -1) {
          obj[key] = moment(value, 'HH:mm');
        } else if (dateTimes.indexOf(key) !== -1) {
          obj[key] = moment(value, 'DD-MM-YYYY HH:mm');
        } else {
          obj[key] = value;
        }
        continue;
      }

      className = relations[key];
      if (typeof relations[key] === 'object' && this.isEnum(relations[key])) {  // enum type принимаем строкой
        // obj[key] = relations[key][obj[key]];
        // console.warn('enumb?', obj[key], '=>', relations[key][obj[key]]);
        continue;
      }
      // console.warn('ModelMapper.mapper', value, className, relations[key], obj[key]);
      // obj[key] = BasicResponse.getInstance(className, value);
      obj[key] = ModelMapper.mapper(value, className);
    }

    return obj;
  }

  isEnum(instance: object): boolean {
    const keys = Object.keys(instance);
    const values = [];

    for (const key of keys) {
      let value = instance[key];

      if (typeof value === 'number') {
        value = value.toString();
      }

      values.push(value);
    }

    for (const key of keys) {
      if (values.indexOf(key) < 0) {
        return false;
      }
    }

    if (values.length === 0) {
      return false;
    }

    return true;
  }
}

export function propertyMap(sourceProperty: string) {
  return (target: any, propertyKey: string) => {
    if (!target.constructor._propertyMap) {
      target.constructor._propertyMap = {};
    }
    target.constructor._propertyMap[propertyKey] = sourceProperty;
  };
}
