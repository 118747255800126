import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService} from '../shared/SDK/api.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  AuthUser: BehaviorSubject<Object> = new BehaviorSubject(new Object());
  ref = '';
  public errorText: string;

  constructor(private router: Router) {
  }
}
